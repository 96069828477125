import { request } from "src/utils/services";

export const getUsers = async () => {
  const { payload } = await request("user.list");

  return payload;
};

// export const approveTransactionById = async (id: string) => {
//   return request("admin.billing.withdraw.approve", { id });
// };
// export const getPaymentList = async () => {
//   return request("billing.paymethod", {});
// };
