import { TRoute } from "../../utils/routes";
import DriverPageHome from "./Page/Home";

const DriverRouter: TRoute[] = [
  {
    path: "/driver",
    name: "driver",
    title: "Driver",
    component: DriverPageHome,
  },
];

export default DriverRouter;
