import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Descriptions,
  Divider,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageChallanModalContext } from "../Modal/ManageChallan";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { getChallanById } from "../Requests";
import CreateDriverModal from "src/modules/Driver/Modal/CreateDriver";
import { getDriver } from "src/modules/Driver/Requests";
import { getParty } from "src/modules/Party/Requests";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import moment from "moment";
import { isMobile } from "react-device-detect";

// import FormFile from '../../../components/Form/File';

const ManageChallanForm: React.FC<{ id: number }> = (props) => {
  const modalContext = React.useContext(ManageChallanModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [sno, setSno] = React.useState(1);

  // const [parameters, setParameters] = React.useState([]);

  const {
    isLoading,
    error,
    data: challan,
  } = useQuery(["calibrationRequest", props.id], async () =>
    getChallanById(props.id)
  );
  challan.challan = challan?.challan;

  challan.challan.gr = challan?.gr[0];
  const [pkg, setPkgs] = React.useState(challan?.challan.totalPackage);
  const [weight, setWeight] = React.useState(challan?.challan.totalWeight);
  const [paidAmt, setPaidAmt] = React.useState(
    challan?.challan.totalPaidAmount
  );
  const [toPayAmt, setToPayAmt] = React.useState(
    challan?.challan.totalToPayAmount
  );

  const ManageChallanMutation = useMutation(
    (values: any) => request("user.challan.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("challan");
        queryClient.invalidateQueries("gr");
        queryClient.invalidateQueries("grforgatepass");
      },
    }
  );
  const {
    // isLoading,
    isError,
    data: driver,
  } = useQuery(["driver"], () => getDriver(), {
    refetchOnWindowFocus: false,
    // enabled: false,
    keepPreviousData: true,
    staleTime: 5000,
    // refetchOnMount: false,
  });
  const {
    // isLoading,
    data: party,
    // error,
    // isError,
  } = useQuery(["party"], async () => getParty(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    values.challanId = props.id;
    values.user_id = LocalStorageService.getAccount().id;
    values.company_id = CookiesService.getCompany();
    values.challanDate = moment(values.challanDate).format(
      "YYYY-MM-DD hh:mm:ss"
    );
    for (let i = 0; i < values?.gr.length; i++) {
      // console.log(values.gr[i].date);
      // values.gr[i].date=""
      values.gr[i].date = moment(values?.gr[i]?.date).add(6, "hour");
      console.log(values.gr[i].date);
    }
    values.totalPackage = pkg;
    values.totalWeight = weight;
    values.totalPaidAmount = paidAmt;
    values.totalToPayAmount = toPayAmt;

    setFormLoading(true);

    ManageChallanMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The challan has been successfully Managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  challan.challan.challanDate = moment(
    challan?.challan?.challanDate,
    "YYYY-MM-DD"
  );
  for (let i = 0; i < challan?.gr[0].length; i++) {
    challan.gr[0][i].date = moment(
      challan?.gr[0][i]?.date,
      "YYYY-MM-DD hh:mm:ss"
    );
  }

  const handleTotal = (_: any, values: any) => {
    // console.log(values, "===>");
    // const rowsCopy = [...values.gr]
    let qty: number = 0; //pkg;
    let wt: number = 0; //weight;
    let paidAmt: number = 0; //paidAmt;
    let toPayAmt: number = 0; //toPayAmt;
    const grList = values.gr;

    grList?.forEach((fieldGroup: any, index: number) => {
      if (
        fieldGroup &&
        (fieldGroup.quantity ||
          fieldGroup.weight ||
          fieldGroup.paidAmount ||
          fieldGroup.toPayAmount)
      ) {
        qty = fieldGroup.quantity ? qty + +fieldGroup.quantity : pkg;
        // console.log('quantity', qty);

        wt = fieldGroup.weight ? wt + +fieldGroup.weight : weight;
        paidAmt = fieldGroup.paidAmount
          ? paidAmt + +fieldGroup.paidAmount
          : paidAmt;
        toPayAmt = fieldGroup.toPayAmount
          ? toPayAmt + +fieldGroup.toPayAmount
          : toPayAmt;
        // console.log(qty, wt, paidAmnt, toPayAmt, "iqty,iweight,paidAmount,topayAmount");
      }
    });
    setPkgs(qty);
    setPaidAmt(paidAmt);
    setWeight(wt);
    setToPayAmt(toPayAmt);
    // console.log(qty, wt, paidAmnt, toPayAmt, "qty,weight,paidAmount,topayAmount");
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={challan.challan}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onValuesChange={handleTotal}
      scrollToFirstError
    >
      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            label="Challan Date"
            name="challanDate"
            tooltip={{
              title: "Challan Date ",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[
            //   { required: true, message: "Please input your Profile name!" },
            // ]}
          >
            <DatePicker format={"DD-MM-YY"} />
            {/* <Input
              addonBefore={<BankOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="Challan No"
            name="challanNo"
            tooltip={{
              title: "Challan No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   type: "number",
              //   message: "The input is not valid E-mail!",
              // },
              { required: true, message: "Please input challan No" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col flex={1}>
          <Form.Item
            label="Lorry Number"
            name="lorryNo"
            tooltip={{
              title: "lorryNo",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   type: "number",
              //   message: "The input is not valid E-mail!",
              // },
              {
                required: true,
                message: "Please input Lorry Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2}>
          <Form.Item
            label="From"
            name="pickupLocation"
            tooltip={{
              title: "Pick-up Location",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
            // addonBefore={<GlobalOutlined className="site-form-item-icon" />}
            // maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item
            label="To"
            name="destination"
            tooltip={{
              title: "Destination",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
            // addonBefore={<IdcardOutlined className="site-form-item-icon" />}
            // maxLength={20}
            />
          </Form.Item>
        </Col>
        {/* <Col flex={2}>
          <Form.Item
            label="Upload Excel File"
            // name="destination"
            tooltip={{
              title: "Upload GR Sheet",
              icon: <InfoCircleOutlined />,
            }}
          // rules={[{ required: true }]}
          >
            <input
              type="file"
              onChange={(e: any) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            /> */}
        {/* <Upload
              onChange={(e: any) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload> */}
        {/* </Form.Item> */}
        {/* <Button
            size="large"
            //loading={formLoading}
            type="primary"
            //htmlType="submit"
            shape="round"
            onClick={() => {
              React.useEffect(() => {
                form.setFieldsValue(party);
              }, [form, party]);
            }}
          >
            Submit
          </Button> */}
        {/* <input
            type="file"
            onChange={(e: any) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          /> */}
        {/* </Col> */}
      </Row>

      {/* <Row gutter={20}>
        <Col flex={2}>
          <Form.Item
            label="Address"
            name="address"
            tooltip={{
              title: "Office Address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={1} maxLength={500} showCount />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item
            label="Pincode"
            name="pinCode"
            tooltip={{
              title: "Eight digit pincode",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Input
              addonBefore={
                <EnvironmentOutlined className="site-form-item-icon" />
              }
              maxLength={8}
            />
          </Form.Item>
        </Col>
      </Row> */}
      <h3>GR List</h3>
      {!isMobile && (
        <table>
          <tr>
            <th style={{ width: "6%" }}>Sno</th>
            <th style={{ width: "12%" }}>Date</th>
            <th style={{ width: "15%" }}>GR Number</th>
            <th style={{ width: "15%" }}>Content</th>
            <th style={{ width: "8%" }}>No.of Pkg</th>
            <th style={{ width: "12%" }}>Weight</th>
            <th style={{ width: "11%" }}>Paid Amt</th>
            <th style={{ width: "9%" }}> To Pay Amount</th>
            <th style={{ width: "8%" }}>Pvt Mark</th>
          </tr>
        </table>
      )}
      <Form.List name="gr">
        {(fields, { add, remove }) => (
          <>
            {fields.map(
              (field: any, index: number) => (
                setSno(index + 1),
                (
                  <Row
                    //gutter={16}
                    key={field.key}
                    justify="space-between"
                    align="top"
                  >
                    <Col style={{ width: !isMobile ? "6%" : "18%" }}>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area ||
                          prevValues.sights !== curValues.sights
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            name={[field.name, "challanSno"]}
                          >
                            <Input placeholder="S.no" />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "12%" : "36%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "date"]}
                        fieldKey={[field.fieldKey, "date"]}
                      >
                        <DatePicker format={"DD-MM-YY"} />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "15%" : "45%" }}>
                      <Form.Item
                        {...field}
                        //label="Mobile"
                        name={[field.name, "grNumber"]}
                        fieldKey={[field.fieldKey, "grNumber"]}
                        rules={[{ required: true, message: "GR No required" }]}
                      >
                        <Input placeholder="GR Number" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "15%" : "45%" }}>
                      <Form.Item
                        {...field}
                        //label="Alt. Mobile"
                        name={[field.name, "content"]}
                        fieldKey={[field.fieldKey, "content"]}
                        rules={[
                          { required: true, message: "Content Required" },
                        ]}
                      >
                        <Input placeholder="Content" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "8%" : "18%" }}>
                      <Form.Item
                        {...field}
                        //  label="Email"
                        name={[field.name, "quantity"]}
                        fieldKey={[field.fieldKey, "quantity"]}
                      >
                        <InputNumber min={1} max={1000} placeholder="Pkgs" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "12%" : "36%" }}>
                      <Form.Item
                        {...field}
                        //  label="Email"
                        name={[field.name, "weight"]}
                        fieldKey={[field.fieldKey, "weight"]}
                      >
                        <Input
                          // addonBefore={
                          //   <MailOutlined className="site-form-item-icon" />
                          // }
                          placeholder="Weight"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "10%" : "25%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "paidAmount"]}
                        fieldKey={[field.fieldKey, "PaidAmount"]}
                      >
                        <Input
                          // addonBefore={
                          //   <MailOutlined className="site-form-item-icon" />
                          // }
                          placeholder="Paid Amount"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "10%" : "25%" }}>
                      <Form.Item
                        {...field}
                        //  label="Email"
                        name={[field.name, "toPayAmount"]}
                        fieldKey={[field.fieldKey, "toPayAmount"]}
                      >
                        <Input placeholder="To Pay Amount" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "10%" : "40%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "partyId"]}
                        fieldKey={[field.fieldKey, "partyId"]}
                        rules={[
                          { required: true, message: "PrivateMark required" },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option!.children as unknown as string).includes(
                              input
                            )
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                              // .toLowerCase()
                              .localeCompare(
                                optionB!.children as unknown as string
                                //.toLowerCase()
                              )
                          }
                          placeholder="PVT Mark"
                          dropdownRender={(menu) => (
                            <>
                              <CreatePartyModal />
                              {menu}
                            </>
                          )}
                          // onChange={getInstrumentsParameters}
                        >
                          {party &&
                            party?.Party?.map((item: any, i: number) => {
                              return (
                                <Select.Option value={item?.id} key={i}>
                                  {item?.privateMark}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                    {isMobile && <Divider />}
                  </Row>
                )
              )
            )}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add GR
              </Button>
            </Form.Item>
            <div>
              <Descriptions
                //title="Responsive Descriptions"
                bordered
                column={!isMobile ? 4 : 1}
              >
                <Descriptions.Item label="Total Package">
                  {pkg}
                </Descriptions.Item>
                <Descriptions.Item label="Total Weight">
                  {weight}
                </Descriptions.Item>
                <Descriptions.Item label="Total Paid Amount">
                  ₹ {paidAmt}
                </Descriptions.Item>
                <Descriptions.Item label="Total To Pay Amount">
                  ₹ {toPayAmt}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </>
        )}
      </Form.List>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
          //onClick={resetForm}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageChallanForm;
