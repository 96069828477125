import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { UnloadingModalContext } from "../Modal/UnloadingChallanDetails";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { getChallanById } from "../Requests";
import CreateDriverModal from "src/modules/Driver/Modal/CreateDriver";
import { getDriver } from "src/modules/Driver/Requests";
import { getParty } from "src/modules/Party/Requests";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import ChallanList from "../Component/Home/List";
import moment from "moment";

// import FormFile from '../../../components/Form/File';

const UnloadingForm: React.FC<{ id: number }> = (props) => {
  const modalContext = React.useContext(UnloadingModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [parameters, setParameters] = React.useState([]);
  const [sno, setSno] = React.useState(1);

  const {
    isLoading,
    error,
    data: challan,
  } = useQuery(["calibrationRequest", props.id], async () =>
    getChallanById(props.id)
  );
  challan.challan = challan?.challan;

  //challan.challan.gr = challan?.gr;
  //console.log(challan, "data");
  const ManageChallanMutation = useMutation(
    (values: any) =>
      request("user.challan.unloading.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("challan");
      },
    }
  );
  const {
    // isLoading,
    isError,
    data: driver,
  } = useQuery(["driver"], () => getDriver(), {
    refetchOnWindowFocus: false,
    // enabled: false,
    keepPreviousData: true,
    staleTime: 5000,
    // refetchOnMount: false,
  });
  const {
    // isLoading,
    data: party,
    // error,
    // isError,
  } = useQuery(["party"], async () => getParty(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const onFinish = async (values: any) => {
    values.grStatus = "At Warehouse";

    setFormLoading(true);

    ManageChallanMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The challan has been successfully Managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  challan.challan.unLoadingDate = challan.challan.unLoadingDate
    ? moment(challan?.challan?.unLoadingDate, "YYYY-MM-DD")
    : null;
  return (
    <Form
      style={{ textAlign: "center" }}
      layout="horizontal"
      initialValues={challan.challan}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // initialValues={challan.challan}
      autoComplete="off"
    >
      <h4>Challan Number: {challan.challan.challanNo}</h4>

      <Form.Item
        label="Unloading Date"
        name="unLoadingDate"
        tooltip={{
          title: "Unloading Date ",
          icon: <InfoCircleOutlined />,
        }}
      >
        <DatePicker format={"DD-MMM-YYYY"} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
    // <Form
    //   layout="vertical"
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    //   initialValues={challan?.Challan}
    // >
    //   <Form.Item
    //     label="Instruments"
    //     name="instruments"
    //     tooltip={{
    //       title: "Instrument",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select placeholder="Instruments" onChange={getInstrumentsParameters}>
    //       {instrumentsData &&
    //         instrumentsData?.companyInstruments?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.id} key={i}>
    //               {item?.title}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>
    //   <Form.Item
    //     label="Parameters"
    //     name="parameters"
    //     tooltip={{
    //       title: "Parameters",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select
    //       //mode="multiple"
    //       placeholder="Parameters"
    //       allowClear
    //     >
    //       {console.log(parameters, "parameters")};
    //       {parameters &&
    //         parameters?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.title} key={i}>
    //               {item?.title}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>

    //   <Form.Item
    //     label="Requested By"
    //     name="name"
    //     tooltip={{
    //       title: "Concerned Person Name",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select placeholder="Requested By" allowClear>
    //       {console.log(companyData, "companyData")};
    //       {companyData?.concernedPerson &&
    //         companyData?.concernedPerson?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.name} key={i}>
    //               {item?.name}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>
    //   <Form.Item
    //     label="Remarks"
    //     name="remarks"
    //     tooltip={{
    //       title: "Description/ Remarks",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     // rules={[{ required: true }]}
    //   >
    //     <Input.TextArea
    //       placeholder="Enter Description/Remarks"
    //       autoSize={{ minRows: 3, maxRows: 5 }}
    //     />
    //   </Form.Item>
    //   {/* <Form.Item
    //             label="Logo"
    //             name="avatar"
    //             tooltip={{
    //                 title: 'Payment Method Icon',
    //                 icon: <InfoCircleOutlined />,
    //             }}
    //             rules={[{ required: true }]}
    //         >
    //             <FormFile />
    //         </Form.Item> */}
    //   {/* <Form.Item
    //             label="Status"
    //             name="isactive"
    //             tooltip={{
    //                 title: 'Display to user',
    //                 icon: <InfoCircleOutlined />,
    //             }}
    //             valuePropName="checked"
    //         >
    //             <Switch
    //                 checkedChildren="Active"
    //                 unCheckedChildren="Inactive"
    //                 defaultChecked
    //             />
    //         </Form.Item> */}

    //   <Form.Item style={{ textAlign: "center" }}>
    //     <Button
    //       size="large"
    //       loading={formLoading}
    //       type="primary"
    //       htmlType="submit"
    //       shape="round"
    //     >
    //       Submit
    //     </Button>
    //   </Form.Item>
    // </Form>
  );
};

export default UnloadingForm;
