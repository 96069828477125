import { request } from "src/utils/services";
// import axios from "axios";
// import LocalStorageService from "src/utils/local-storage";
export const getProfileByUser = async (id: string) => {
  const payload = await request("user.profile.active", { id });
  return payload.payload;
  // const headers = {
  //   Authorization: "Bearer " + LocalStorageService.getToken(),
  // };

  // const response = await axios.get(
  //   "http://localhost:8080/api/user/profilebyuser/" + id,
  //   {
  //     headers: headers,
  //   }
  // );
  // return response;
};
export const createProfile = async (id: string, data: any) => {
  data.user_id = id;
  console.log(data, "createcompay");
  const { payload } = await request("user.profile.create", {}, data);
  return payload;
};

export const updateProfile = async (id: string, data: any) => {
  console.log(data, "update Profile");
  const { payload } = await request("user.profile.update", { id }, data);
  return payload;
};

export const getAllStats = async () => {
  // console.log(data, "update Profile");
  const { payload } = await request("user.profile.stats");
  return payload;
};
