import * as React from "react";
// import LayoutHeader from './Header/Header';
// import LayoutHeaderMobile from './Header/Mobile';
// import LayoutFooter from './Footer';
import "./Layout.css";
import Gatepass from "../../modules/Gate Pass/Form/GatePass";
import { Col, Layout, Menu, Row, Typography } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CarOutlined,
  UserOutlined,
  PieChartOutlined,
  FileSearchOutlined,
  BankOutlined,
  GlobalOutlined,
  OrderedListOutlined,
  FormOutlined,
  LogoutOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import NavRouteLink from "../NavRouteLink";
import RouteLink from "../RouteLink";
import CookiesService from "src/utils/cookie";
import { useHistory, useLocation } from "react-router-dom";
import Challan from "src/modules/Challan/Form/Challan";
import LocalStorageService from "src/utils/local-storage";
import { TabBar } from "antd-mobile";
import {
  AppOutline,
  CollectMoneyOutline,
  TruckOutline,
  FillinOutline,
  TeamOutline,
  UserSetOutline,
  CloseShieldOutline,
} from "antd-mobile-icons";
import { isMobile } from "react-device-detect";
const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
const { Text } = Typography;
const AuthLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const [collapsed, setCollapsed] = React.useState(true);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const setRouteActive = (value: string) => {
    history.push(value);
  };

  const tabs =
    LocalStorageService.getRoles() === "ROLE_VIEWER"
      ? [
          {
            key: "/",
            title: (
              <Text
                style={{
                  color: pathname === "/" ? "black" : "#ffffff",
                  fontWeight: pathname === "/" ? "bold" : "normal",
                }}
              >
                Dashboard
              </Text>
            ),
            icon: <AppOutline color={pathname === "/" ? "black" : "#ffffff"} />,
          },
          {
            key: "/gr",
            title: (
              <Text
                style={{
                  color: pathname === "/gr" ? "black" : "#ffffff",
                  fontWeight: pathname === "/gr" ? "bold" : "normal",
                }}
              >
                Gr-List
              </Text>
            ),
            icon: (
              <CollectMoneyOutline
                color={pathname === "/gr" ? "black" : "#ffffff"}
              />
            ),
          },
        ]
      : [
          {
            key: "/",
            title: (
              <Text
                style={{
                  color: pathname === "/" ? "black" : "#ffffff",
                  fontWeight: pathname === "/" ? "bold" : "normal",
                }}
              >
                Dashboard
              </Text>
            ),
            icon: <AppOutline color={pathname === "/" ? "black" : "#ffffff"} />,
          },
          {
            key: "/party",
            title: (
              <Text
                style={{
                  color: pathname === "/party" ? "black" : "#ffffff",
                  fontWeight: pathname === "/party" ? "bold" : "normal",
                }}
              >
                Party
              </Text>
            ),
            icon: (
              <TeamOutline
                color={pathname === "/party" ? "black" : "#ffffff"}
              />
            ),
          },
          {
            key: "/challan",
            title: (
              <Text
                style={{
                  color: pathname === "/challan" ? "black" : "#ffffff",
                  fontWeight: pathname === "/challan" ? "bold" : "normal",
                }}
              >
                Challan
              </Text>
            ),
            icon: (
              <TruckOutline
                color={pathname === "/challan" ? "black" : "#ffffff"}
              />
            ),
          },
          {
            key: "/gr",
            title: (
              <Text
                style={{
                  color: pathname === "/gr" ? "black" : "#ffffff",
                  fontWeight: pathname === "/gr" ? "bold" : "normal",
                }}
              >
                Gr-List
              </Text>
            ),
            icon: (
              <CollectMoneyOutline
                color={pathname === "/gr" ? "black" : "#ffffff"}
              />
            ),
          },

          {
            key: "/driver",
            title: (
              <Text
                style={{
                  color: pathname === "/driver" ? "black" : "#ffffff",
                  fontWeight: pathname === "/driver" ? "bold" : "normal",
                }}
              >
                Staff
              </Text>
            ),
            icon: (
              <UserSetOutline
                color={pathname === "/driver" ? "black" : "#ffffff"}
              />
            ),
          },
          {
            key: "/gatepass",
            title: (
              <Text
                style={{
                  color: pathname === "/gatepass" ? "black" : "#ffffff",
                  fontWeight: pathname === "/gatepass" ? "bold" : "normal",
                }}
              >
                Gate Pass
              </Text>
            ),
            icon: (
              <FillinOutline
                color={pathname === "/gatepass" ? "black" : "#ffffff"}
              />
            ),
          },
          // {
          //   key: "/logout",
          //   title: "Gate Pass",
          //   icon: <FillinOutline />,
          // },
        ];

  React.useEffect(() => {
    if (!CookiesService.getToken()) {
      window.location.reload();
    }
  }, [CookiesService.getToken()]);
  return (
    <>
      {location.pathname.includes("gatepasspdf") ||
      location.pathname.includes("challanpdf") ? (
        <>
          {location.pathname.includes("gatepasspdf") && <Gatepass />}
          {location.pathname.includes("challanpdf") && <Challan />}
        </>
      ) : (
        <>
          <Layout>
            {!isMobile && (
              <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo" />
                {/* <img width={10} src={logo} />
          </div> */}
                <Menu
                  mode="inline"
                  //defaultSelectedKeys={["gr"]}
                  //defaultOpenKeys={["gr"]}
                  style={{ height: "100vh" }}
                >
                  <Menu.Item key="1" icon={<PieChartOutlined />}>
                    <NavRouteLink name="dashboard">Dashboard</NavRouteLink>
                  </Menu.Item>
                  {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Company"> */}
                  <Menu.Item key="2" icon={<BankOutlined />}>
                    <NavRouteLink name="profile"> Company Profile</NavRouteLink>
                  </Menu.Item>
                  <Menu.Item key="3" icon={<UserOutlined />}>
                    <NavRouteLink name="party">Party</NavRouteLink>
                  </Menu.Item>

                  {/* </SubMenu> */}
                  <Menu.Item key="4" icon={<CarOutlined />}>
                    <NavRouteLink name="driver">Assigned Person</NavRouteLink>
                  </Menu.Item>
                  <Menu.Item key="5" icon={<FileSearchOutlined />}>
                    <NavRouteLink name="challan">Challan</NavRouteLink>
                  </Menu.Item>
                  <Menu.Item key="6" icon={<OrderedListOutlined />}>
                    <NavRouteLink name="gr">GR List</NavRouteLink>
                  </Menu.Item>
                  <Menu.Item key="7" icon={<FormOutlined />}>
                    <NavRouteLink name="gatepass">Gate Pass</NavRouteLink>
                  </Menu.Item>
                  {LocalStorageService.getRoles() === "ROLE_ADMIN" ? (
                    <Menu.Item key="8" icon={<ToolOutlined />}>
                      <NavRouteLink name="users">Change Password</NavRouteLink>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
              </Sider>
            )}
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }}>
                <Row justify="space-between">
                  <Col>
                    {React.createElement(
                      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                      {
                        className: "trigger",
                        onClick: toggle,
                      }
                    )}
                  </Col>

                  <Col style={{ paddingRight: "2rem", fontWeight: "bold" }}>
                    {/* <Menu mode="horizontal">
                    <Menu.Item key="0" icon={<LogoutOutlined />}>
                      <NavRouteLink name="logout">Logout</NavRouteLink>
                    </Menu.Item>
                  </Menu> */}
                    <RouteLink name="logout">
                      {" "}
                      {LocalStorageService.getRoles() === "ROLE_ADMIN"
                        ? "You Are Login As ADMIN  "
                        : ""}
                      {""}
                      <LogoutOutlined />
                      Logout
                    </RouteLink>
                  </Col>
                </Row>
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  margin: isMobile ? "0" : "24px 16px",
                  padding: 24,
                  minHeight: "85vh",
                }}
              >
                {props.children}
              </Content>
              {isMobile && (
                <TabBar
                  className="site-layout-background"
                  activeKey={pathname}
                  onChange={(value) => setRouteActive(value)}
                  style={{ backgroundColor: "#1890FF" }}
                >
                  {tabs.map((item) => (
                    <TabBar.Item
                      key={item.key}
                      icon={item.icon}
                      title={item.title}
                    />
                  ))}
                </TabBar>
              )}
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

export default AuthLayout;
