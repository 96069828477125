import { TRoute } from "../../utils/routes";
import UsersPageHome from "./Page/Home";

const UsersRouter: TRoute[] = [
  {
    path: "/users",
    name: "users",
    title: "users",
    component: UsersPageHome,
  },
];

export default UsersRouter;
