import * as React from "react";
// import { useQuery } from 'react-query';
// import { Modal } from 'react-bootstrap';
import CreateGatepassForm from "../Form/CreateGatePassForm";
import { Modal, Button } from "antd";

export const CreateGatepassModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateGatepassModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateGatepassModalContext.Provider value={{ setIsModalVisible }}>
      <Button onClick={showModal} style={{ display: "inline-flex" }}>
        <i className="ki ki-plus" /> Add Gatepass
      </Button>
      <Modal
        title="Generate Gatepass"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateGatepassForm />
      </Modal>
    </CreateGatepassModalContext.Provider>
  );
};

export default CreateGatepassModal;
