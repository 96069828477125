import { Avatar, Tag } from "antd";
import moment from "moment";
import * as React from "react";
import SVG from "react-inlinesvg";
// import { request } from 'src/utils/services';
import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import { Datatable } from "../../../../components/Datatable";
import DriverManageModal from "../../Modal/ManageDriver";
import { getDriver, getDriverById } from "../../Requests";
//import { getInstrumentById } from 'src/modules/Instruments/Requests';
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { Button, Tooltip, Space, Input, Table } from "antd";
//import DriverViewModal from "../../Modal/ShowDriverDetails";
import {
  EyeOutlined,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { isMobile } from "react-device-detect";
interface DataType {
  //challanNo: any;
  id: number;
  name: any;
  // render?:(_:any,record:any)
  //address: string;
}
type DataIndex = keyof DataType;

const DriverList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<Input>(null);
  // console.log(CookiesService.getToken(), "token")
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["driver", LocalStorageService.getAccount().id, page, size],
    () => getDriver(),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
      keepPreviousData: true,
      staleTime: 5000,
      // refetchOnMount: false,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const column: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => moment(text).format("D MMM y"),
    },
    {
      title: "Name.",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),

      // render: (text: any) => (
      //   //getInstrumentById(id);
      //   <p>{"SKY/ENQ/" + text}</p>
      // ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      //     render: (id: any) => {
      //         getInstrumentById(id);

      //     }
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <DriverViewModal id={id} /> */}
          <DriverManageModal id={id} />
        </Space>
      ),
    },
  ];

  return isMobile ? (
    <Table
      size="small"
      columns={column}
      dataSource={response?.Driver}
      scroll={{ x: "100vw", y: "55vh" }}
    />
  ) : (
    <Table
      size="small"
      columns={column}
      dataSource={response?.Driver}
      // scroll={{ x: "100vw", y: "55vh" }}
    />
  );
};

export default DriverList;
