import { Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import DriverList from "../Component/Home/List";
import CreateDriverModal from "../Modal/CreateDriver";

const DriverPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Assigned Person List</h2>
              <CreateDriverModal />
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <DriverList />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(DriverPageHome);
