import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ShowGatepassDetailsForm from "../Form/ShowGatePassForm";
import {
  EyeOutlined,

  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import generatePDF from "react-to-pdf";
export const ShowGatepassDetailsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const GatepassViewModal: React.FC<{ id: number }> = ({ id }) => {
  const { push } = useHistory();
  const targetRef = useRef();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowGatepassDetailsModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Tooltip>
      <Modal
        width={600}
        // width={800}
        title=" View Gatepass Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <h1>Edit Gatepass</h1> */}
        {/* <div ref={targetRef}> */}
        <ShowGatepassDetailsForm id={id} />
        {/* </div> */}

        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Button type="primary" onClick={() => push("/gatepasspdf/" + id)}>
            Print
          </Button>
          {/* <Button
            onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
          >
            Download PDF
          </Button> */}
        </div>
      </Modal>
    </ShowGatepassDetailsModalContext.Provider>
  );
};

export default GatepassViewModal;
