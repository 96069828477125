import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";

import ShowChallanDetailsForm from "../Form/ShowChallanDetailsForm";
import {
  EyeOutlined,

  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
export const ShowChallanDetailsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const ChallanViewModal: React.FC<{ id: number }> = ({ id }) => {
  const { push } = useHistory();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowChallanDetailsModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Tooltip>
      <Modal
        width={1000}
        title=" View Challan Request Form"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ShowChallanDetailsForm id={id} />
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => push("/challanpdf/" + id)}
          >
            Print
          </Button>
        </div>
      </Modal>
    </ShowChallanDetailsModalContext.Provider>
  );
};

export default ChallanViewModal;
