import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Divider,
  Card,
  Tag,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { CreateGatepassModalContext } from "../Modal/CreateGatePass";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { values } from "lodash";
import { getParty } from "src/modules/Party/Requests";
import { getChallan } from "src/modules/Challan/Requests";
import CreateChallanModal from "src/modules/Challan/Modal/CreateChallan";
import { getGr, getGrById, getGrGatepass } from "src/modules/GR List/Requests";
import { getDriver } from "src/modules/Driver/Requests";
import ShowGrCard from "../../GR List/Component/Home/ShowGrCard";
import moment from "moment";
import GrList from "src/modules/GR List/Component/Home/List";
import { isMobile } from "react-device-detect";

// import FormFile from '../../../components/Form/File';

const CreateGatepassForm: React.FC = () => {
  const modalContext = React.useContext(CreateGatepassModalContext);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [parameters, setParameters] = React.useState([]);
  const [freightAmount, setFreightAmount] = React.useState(0);
  const [gatepassCharge, setGatepassCharge] = React.useState(0);
  const [biltyCharge, setBiltyCharge] = React.useState(0);

  const [doorDeliveryCharge, setDoorDeliveryCharge] = React.useState(0);
  const [rebate, setRebate] = React.useState(0);
  const [miscCharge, setMiscCharge] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  // const [quantity, setQuantity] = React.useState(0);
  // const [weight, setWeight] = React.useState(0);
  const [challanId, setChallanId] = React.useState(0);
  const [partyId, setPartyId] = React.useState(0);
  const [grData, setGrData] = React.useState<[object] | any>(undefined);

  const createGatepassMutation = useMutation(
    (values: any) => request("user.gatepass.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("gatepass");
        queryClient.invalidateQueries("gr");
        queryClient.invalidateQueries("grforgatepass");
      },
    }
  );

  const {
    // isLoading,
    //isLoading,
    //isError,
    data: gr,

    // isError,
  } = useQuery(["grforgatepass", page, size], async () => getGrGatepass(), {
    refetchOnWindowFocus: true,
    // enabled: false,
    keepPreviousData: true,
    staleTime: 5000,
  });
  const {
    // isLoading,
    //isLoading,
    //isError,
    data: driver,

    // isError,
  } = useQuery(["driver"], async () => getDriver(), {
    refetchOnWindowFocus: true,
    // enabled: false,
    keepPreviousData: true,
    staleTime: 5000,
  });

  // const mutateGR = useMutation((id: number) => getGrById(id));
  const [grId, setGrId] = React.useState(0);

  const onGrChange = async (id: number) => {
    setGrId(id);

    setGrData(gr?.Gr[0].filter((gr: any) => gr.id === id));
    // mutateGR
    //   .mutateAsync(id)
    //   .then((payload: any) => {
    //     // setQuantity(payload?.Gr.quantity);
    //     // setWeight(payload?.Gr.weight);
    //     setFreightAmount(payload?.Gr.toPayAmount);
    //     setChallanId(payload?.Gr[0][0].challanId);
    //     setPartyId(payload?.Gr[0][0].partyId);
    //   })
    //   .catch((e: any) => {
    //     console.log("error", e);
    //   });
  };
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    getTotalAmount();
    values.totalAmount = totalAmount;
    values.challanId = grData[0].challanId;
    values.partyId = grData[0].partyId;
    values.gatePassDate = moment(values.gatePassDate).format("YYYY-MM-DD");

    setFormLoading(true);

    createGatepassMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The gatepass has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
        setTotalAmount(0);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getTotalAmount = () => {
    let tAmt =
      freightAmount +
      gatepassCharge +
      biltyCharge +
      doorDeliveryCharge +
      miscCharge -
      rebate;
    setTotalAmount(tAmt);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      //initialValues={profileData.Profile}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {isMobile && <Row>{grId !== 0 && <ShowGrCard id={grId} />}</Row>}
      <Row gutter={10}>
        <Col style={{ width: isMobile ? "100%" : "75%" }}>
          <Row gutter={20}>
            <Col flex={1}>
              <Form.Item
                label="Gate Pass Date"
                name="gatePassDate"
                tooltip={{
                  title: "Gatepass Date",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <DatePicker format={"DD-MM-YY"} />
                {/* <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="GR Number"
                name="grId"
                tooltip={{
                  title: "GR",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  // {
                  //   type: "email",
                  //   message: "The input is not valid E-mail!",
                  // },
                  {
                    required: true,
                    //message: "Please input your E-mail!",
                  },
                ]}
              >
                <Select
                  // allowClear
                  onChange={onGrChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      // .toLowerCase()
                      .localeCompare(
                        optionB!.children as unknown as string
                        //.toLowerCase()
                      )
                  }
                  placeholder="Select GR Number"

                  // onChange={getInstrumentsParameters}
                >
                  {gr &&
                    gr?.Gr[0]?.map((item: any, i: number) => {
                      // const { id, name } = item?.companyInstruments?.id;
                      //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
                      return (
                        <Select.Option value={item?.id} key={i}>
                          {item?.grNumber}
                        </Select.Option>
                      );
                    })}
                </Select>
                {/* <Input
              addonBefore={<MailOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Quantity"
                name="quantity"
                tooltip={{
                  title: "Quantity",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
                {/* <Input
              addonBefore={<IdcardOutlined className="site-form-item-icon" />}
              maxLength={20}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Weight"
                name="weight"
                tooltip={{
                  title: "Weight",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
                {/* <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col flex={1}>
              <Form.Item
                label="Freight Amount"
                name="freightAmount"
                tooltip={{
                  title: "Freight Amount",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  // defaultValue={freightAmount}
                  onChange={(e: any) => {
                    setFreightAmount(parseFloat(e.target.value));
                  }}
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Invoice Date"
                name="invoiceDate"
                tooltip={{
                  title: "Invoice Date",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <DatePicker format={"DD-MM-YY"} />
                {/* <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Invoice Number"
                name="invoiceNumber"
                tooltip={{
                  title: "Invoice Number",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Fixed Amount"
                name="miscCharge"
                tooltip={{
                  title: "Miscellaneous Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setMiscCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <Divider /> */}
          <Row gutter={20}>
            <Col flex={2}>
              <Form.Item
                label="Gate Pass Charge"
                name="gatepassCharge"
                tooltip={{
                  title: "GatePass Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setGatepassCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>

            <Col flex={2}>
              <Form.Item
                label="Bilty Charge"
                name="biltyCharge"
                tooltip={{
                  title: "Bilty Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setBiltyCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
            <Col flex={2}>
              <Form.Item
                label="Door Delivery Charge"
                name="doorDeliveryCharge"
                tooltip={{
                  title: "Door Delivery Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setDoorDeliveryCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
            <Col flex={2}>
              <Form.Item
                label="Rebate"
                name="rebate"
                tooltip={{
                  title: "Rebate",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    console.log(e, "e");
                    console.log(parseFloat(e.target.value), "f");
                    setRebate(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col flex={1}>
              <Form.Item
                label="Assigned to "
                name="driverId"
                tooltip={{
                  title: "Driver Name",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      // .toLowerCase()
                      .localeCompare(
                        optionB!.children as unknown as string
                        //.toLowerCase()
                      )
                  }
                  placeholder="Select Assigned Person"

                  // onChange={getInstrumentsParameters}
                >
                  {driver &&
                    driver?.Driver?.map((item: any, i: number) => {
                      // const { id, name } = item?.companyInstruments?.id;
                      //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
                      return (
                        <Select.Option value={item?.id} key={i}>
                          {item?.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col flex={2}>
              <Form.Item
                label="Received With Thanks"
                name="deliveredTo"
                tooltip={{
                  title: "Delivered To",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
                {/* <Input
              addonBefore={<IdcardOutlined className="site-form-item-icon" />}
              maxLength={20}
            /> */}
              </Form.Item>
            </Col>

            <Col flex={2}>
              <Form.Item
                label="Status"
                name="grStatus"
                tooltip={{
                  title: "status of GR",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Select placeholder="Select Status">
                  <Select.Option value="Partial Dispatch">
                    Partial Dispatch
                  </Select.Option>
                  <Select.Option value="Dispatched">Dispatched</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex={4}>
              <Descriptions
                //title="Responsive Descriptions"
                bordered
                column={4}
              >
                <Descriptions.Item label="Total Amount">
                  {totalAmount}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col flex={1}>
              <Form.Item name="totalAmount" style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  // loading={formLoading}
                  type="primary"
                  //htmlType="submit"
                  //shape="round"
                  onClick={() => getTotalAmount()}
                >
                  Get Total
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              size="large"
              loading={formLoading}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
        {!isMobile && (
          <Col style={{ width: "25%" }}>
            {/* {grId !== 0 ? <ShowGrCard id={grId} /> : <Empty />} */}
            {grData ? (
              <Card>
                <Descriptions title="GR Details" column={1} size="small">
                  <Descriptions.Item label="GR No">
                    {grData[0].grNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date">
                    {grData[0].date}
                  </Descriptions.Item>
                  <Descriptions.Item label="Pvt Mark">
                    {grData[0].privateMark}
                  </Descriptions.Item>
                  <Descriptions.Item label="Challan No">
                    {grData[0].challanNo}
                  </Descriptions.Item>
                  <Descriptions.Item label="No.of Pkg">
                    {grData[0].quantity}
                  </Descriptions.Item>
                  <Descriptions.Item label="Weight">
                    {grData[0].weight}
                  </Descriptions.Item>
                  <Descriptions.Item label="Contains">
                    {grData[0].content}
                  </Descriptions.Item>
                  <Descriptions.Item label="Paid Amount">
                    ₹{grData[0].paidAmount}
                  </Descriptions.Item>
                  <Descriptions.Item label="To Pay Amount">
                    ₹{grData[0].toPayAmount}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <>
                      {grData[0].grStatus === "Intransit" && (
                        <Tag color="#f50">{grData[0].grStatus}</Tag>
                      )}
                      {grData[0].grStatus === "At Warehouse" && (
                        <Tag color="#108ee9">{grData[0].grStatus}</Tag>
                      )}
                      {grData[0].grStatus === "Partial Dispatch" && (
                        <Tag color="#e6b800">{grData[0].grStatus}</Tag>
                      )}
                      {grData[0].grStatus === "Dispatched" && (
                        <Tag color="#004d00">{grData[0].grStatus}</Tag>
                      )}
                    </>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ) : (
              <Empty />
            )}
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default CreateGatepassForm;
