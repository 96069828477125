import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Image,
  Card,
  Tag,
} from "antd";
// import { request } from 'src/utils/services';
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { getGatepassById } from "../../../Gate Pass/Requests";
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { queryClient } from 'src';
// import FormFile from '../../../components/Form/File';
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
//import { ShowGatepassDetailsModalContext } from '../Modal/ShowGatepassDetails';
// import Item from 'antd/lib/list/Item';
import logo from "../../../assets/img/logo.png";
import moment from "moment";
import SVG from "react-inlinesvg";
import { Datatable } from "../../../../components/Datatable";
import { getProfileByUser } from "src/modules/Profile/Requests";
import LocalStorageService from "src/utils/local-storage";
import { getChallanById } from "src/modules/Challan/Requests";
import { getGrById } from "src/modules/GR List/Requests";
//import { getInstrumentById, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';

const { Step } = Steps;

const ShowGrCard: React.FC<{ id: number }> = (props) => {
  //const [parameters, setParameters] = React.useState([]);

  const { isLoading, error, data } = useQuery(
    ["GatepassRequest", props.id],
    async () => getGrById(props?.id)
  );
  const {
    //isLoading,
    //error,
    data: profile,
  } = useQuery(["profile", LocalStorageService.getAccount().id], async () =>
    getProfileByUser(LocalStorageService.getAccount().id)
  );
  const {
    //isLoading,
    //error,
    data: challan,
  } = useQuery(["challan", LocalStorageService.getAccount().id], async () =>
    getChallanById(LocalStorageService.getAccount().id)
  );

  return (
    //<h1> {Instrument?.payload?.status}</h1>
    <>
      <Card>
        <Descriptions title="GR Details" column={1} size="small">
          <Descriptions.Item label="GR No">
            {data.Gr[0][0].grNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {data.Gr[0][0].date}
          </Descriptions.Item>
          <Descriptions.Item label="Pvt Mark">
            {data.Gr[0][0].privateMark}
          </Descriptions.Item>
          <Descriptions.Item label="Challan No">
            {data.Gr[0][0].challanNo}
          </Descriptions.Item>
          <Descriptions.Item label="No.of Pkg">
            {data.Gr[0][0].quantity}
          </Descriptions.Item>
          <Descriptions.Item label="Weight">
            {data.Gr[0][0].weight}
          </Descriptions.Item>
          <Descriptions.Item label="Contains">
            {data.Gr[0][0].content}
          </Descriptions.Item>
          <Descriptions.Item label="Paid Amount">
            ₹{data.Gr[0][0].paidAmount}
          </Descriptions.Item>
          <Descriptions.Item label="To Pay Amount">
            ₹{data.Gr[0][0].toPayAmount}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <>
              {data.Gr[0][0].grStatus === "Intransit" && (
                <Tag color="#f50">{data.Gr[0][0].grStatus}</Tag>
              )}
              {data.Gr[0][0].grStatus === "At Warehouse" && (
                <Tag color="#108ee9">{data.Gr[0][0].grStatus}</Tag>
              )}
              {data.Gr[0][0].grStatus === "Partial Dispatch" && (
                <Tag color="#e6b800">{data.Gr[0][0].grStatus}</Tag>
              )}
              {data.Gr[0][0].grStatus === "Dispatched" && (
                <Tag color="#004d00">{data.Gr[0][0].grStatus}</Tag>
              )}
            </>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ShowGrCard;
