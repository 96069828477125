import * as React from "react";
import { Form, Input, Button, message, Switch, Select } from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageDriverModalContext } from "../Modal/ManageDriver";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { getDriverById } from "../Requests";

// import FormFile from '../../../components/Form/File';

const ManageDriverForm: React.FC<{ id: number }> = (props) => {
  const modalContext = React.useContext(ManageDriverModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [parameters, setParameters] = React.useState([]);
  const {
    isLoading,
    error,
    data: driver,
  } = useQuery(["driver", props.id], async () => getDriverById(props.id));
  driver.Driver = driver?.Driver;
  // console.log(driver, "data");
  const manageDriverMutation = useMutation(
    (values: any) => request("user.driver.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("driver");
      },
    }
  );
  // const {
  //   data: ,

  //   isError,
  // } = useQuery(
  //   ["company", ],
  //   async () => getDriverById(props.id),
  //   {
  //     refetchOnWindowFocus: false,
  //     // enabled: false,
  //   }
  // );

  //   const {
  //     //isLoading,
  //     //isError,
  //     data: instrumentsData,
  //     //refetch,
  //   } = useQuery(
  //     ["companyActiveInstruments", CookiesService.getCompany()],
  //     () => getActiveInstrumentByCompany(+CookiesService.getCompany()),
  //     {
  //       refetchOnWindowFocus: false,
  //       // enabled: false,
  //       // keepPreviousData: true,
  //       // staleTime: 5000,
  //       refetchOnMount: false,
  //     }
  //   );
  //   //   Get Parameters

  //   // console.log(instrumentsData, "instrumentsData");
  //   const mutateParameters = useMutation((id: number) =>
  //     getActiveParameterByInstruments(id)
  //   );

  //   // Get Parameters Function
  //   const getInstrumentsParameters = async (id: number) => {
  //     console.log(id, "parameters");
  //     //console.log(parameters, "parameters");
  //     mutateParameters
  //       .mutateAsync(id)
  //       .then((payload) => {
  //         setParameters(payload?.InstrumentParameters);
  //       })
  //       .catch((e: any) => {
  //         console.log("error", e);
  //       });
  //   };

  const onFinish = async (values: any) => {
    values.id = props.id;
    // console.log("id", values.id);

    values.user_id = LocalStorageService.getAccount().id;
    values.company_id = CookiesService.getCompany();
    // values.company = companyData?.company?.company_name;
    // values.address = companyData?.company?.address;
    // values.pincode = companyData?.company?.pincode;
    // values.mobile_no = companyData?.company?.phone_no;
    // values.email = companyData?.company?.email;
    setFormLoading(true);

    manageDriverMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The driver has been successfully Managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  //     const { Option } = Select;

  // const children = [];
  // for (let i = 10; i < 36; i++) {
  //   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  // }

  // function handleChange(value) {
  //   console.log(`selected ${value}`);
  // }

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={driver?.Driver}
    >
      <Form.Item
        label="Driver Name"
        name="name"
        tooltip={{
          title: "Driver Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
        {/* <Select placeholder="Instruments" onChange={getInstrumentsParameters}>
          {instrumentsData &&
            instrumentsData?.companyInstruments?.map((item: any, i: number) => {
              // const { id, name } = item?.companyInstruments?.id;
              //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.title}
                </Select.Option>
              );
            })}
        </Select> */}
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        tooltip={{
          title: "phone",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input maxLength={10} />
        {/* <Select
          //mode="multiple"
          placeholder="mobile number"
          allowClear
        >
          {console.log(parameters, "parameters")};
          {parameters &&
            parameters?.map((item: any, i: number) => {
              // const { id, name } = item?.companyInstruments?.id;
              //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
              return (
                <Select.Option value={item?.title} key={i}>
                  {item?.title}
                </Select.Option>
              );
            })}
        </Select> */}
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        tooltip={{
          title: "address",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        label="Email"
        name="email"
        tooltip={{
          title: "email",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Private Mark"
        name="privateMark"
        tooltip={{
          title: "Private Mark",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item> */}

      {/* <Form.Item
        label="Requested By"
        name="name"
        tooltip={{
          title: "Concerned Person Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      > */}
      {/* <Select placeholder="Requested By" allowClear>
          {console.log(companyData, "companyData")};
          {companyData?.concernedPerson &&
            companyData?.concernedPerson?.map((item: any, i: number) => {
              // const { id, name } = item?.companyInstruments?.id;
              //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
              return (
                <Select.Option value={item?.name} key={i}>
                  {item?.name}
                </Select.Option>
              );
            })}
        </Select> */}
      {/* </Form.Item> */}
      {/* <Form.Item
        label="Remarks"
        name="remarks"
        tooltip={{
          title: "Description/ Remarks",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[{ required: true }]}
      >
        <Input.TextArea
          placeholder="Enter Description/Remarks"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item> */}
      {/* <Form.Item
                label="Logo"
                name="avatar"
                tooltip={{
                    title: 'Payment Method Icon',
                    icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
            >
                <FormFile />
            </Form.Item> */}
      {/* <Form.Item
                label="Status"
                name="isactive"
                tooltip={{
                    title: 'Display to user',
                    icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
            >
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                />
            </Form.Item> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Driver
        </Button>
      </Form.Item>
    </Form>
    // <Form
    //   layout="vertical"
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    //   initialValues={driver?.Driver}
    // >
    //   <Form.Item
    //     label="Instruments"
    //     name="instruments"
    //     tooltip={{
    //       title: "Instrument",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select placeholder="Instruments" onChange={getInstrumentsParameters}>
    //       {instrumentsData &&
    //         instrumentsData?.companyInstruments?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.id} key={i}>
    //               {item?.title}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>
    //   <Form.Item
    //     label="Parameters"
    //     name="parameters"
    //     tooltip={{
    //       title: "Parameters",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select
    //       //mode="multiple"
    //       placeholder="Parameters"
    //       allowClear
    //     >
    //       {console.log(parameters, "parameters")};
    //       {parameters &&
    //         parameters?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.title} key={i}>
    //               {item?.title}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>

    //   <Form.Item
    //     label="Requested By"
    //     name="name"
    //     tooltip={{
    //       title: "Concerned Person Name",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select placeholder="Requested By" allowClear>
    //       {console.log(companyData, "companyData")};
    //       {companyData?.concernedPerson &&
    //         companyData?.concernedPerson?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.name} key={i}>
    //               {item?.name}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>
    //   <Form.Item
    //     label="Remarks"
    //     name="remarks"
    //     tooltip={{
    //       title: "Description/ Remarks",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     // rules={[{ required: true }]}
    //   >
    //     <Input.TextArea
    //       placeholder="Enter Description/Remarks"
    //       autoSize={{ minRows: 3, maxRows: 5 }}
    //     />
    //   </Form.Item>
    //   {/* <Form.Item
    //             label="Logo"
    //             name="avatar"
    //             tooltip={{
    //                 title: 'Payment Method Icon',
    //                 icon: <InfoCircleOutlined />,
    //             }}
    //             rules={[{ required: true }]}
    //         >
    //             <FormFile />
    //         </Form.Item> */}
    //   {/* <Form.Item
    //             label="Status"
    //             name="isactive"
    //             tooltip={{
    //                 title: 'Display to user',
    //                 icon: <InfoCircleOutlined />,
    //             }}
    //             valuePropName="checked"
    //         >
    //             <Switch
    //                 checkedChildren="Active"
    //                 unCheckedChildren="Inactive"
    //                 defaultChecked
    //             />
    //         </Form.Item> */}

    //   <Form.Item style={{ textAlign: "center" }}>
    //     <Button
    //       size="large"
    //       loading={formLoading}
    //       type="primary"
    //       htmlType="submit"
    //       shape="round"
    //     >
    //       Submit
    //     </Button>
    //   </Form.Item>
    // </Form>
  );
};

export default ManageDriverForm;
