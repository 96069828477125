import * as React from "react";
// import { useQuery } from 'react-query';
// import { Modal } from 'react-bootstrap';
import CreatePartyForm from "../Form/CreatePartyForm";
import { Modal, Button } from "antd";
import { DownloadOutlined, UsergroupAddOutlined } from "@ant-design/icons";

export const CreatePartyModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreatePartyModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreatePartyModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        //ghost
        shape="round"
        icon={<UsergroupAddOutlined />}
        //style={{ display: "inline-flex" }}
      >
        {/* <i className="ki ki-plus" /> */}
        Add Party
      </Button>
      <Modal
        title="Generate Party"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreatePartyForm />
      </Modal>
    </CreatePartyModalContext.Provider>
  );
};

export default CreatePartyModal;
