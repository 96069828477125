import { Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import UsersList from "../Component/Home/List";
//import CreateUsersModal from "../Modal/CreateUsers";

const UsersPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Users List</h2>
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <UsersList />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(UsersPageHome);
