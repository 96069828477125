import { TRoute } from "../../utils/routes";
import ProfilePageHome from "./Page/Home";

const ProfileRouter: TRoute[] = [
  {
    path: "/profile",
    name: "profile",
    title: "Profile",
    component: ProfilePageHome,
  },
];

export default ProfileRouter;
