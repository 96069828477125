import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import GrList from "../Component/Home/List";
import { Tabs } from "antd";
import GrSearchBox from "../Component/Home/GrSearchBox";
import { isMobile } from "react-device-detect";
import LocalStorageService from "src/utils/local-storage";
const { TabPane } = Tabs;

//import CreateGrModal from "../Modal/CreateGr";

const GrPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }}>
        {isMobile && (
          <TabPane tab="Search Gr Number" key="1">
            <GrSearchBox />
          </TabPane>
        )}
        {/* {LocalStorageService.getRoles() !== "ROLE_VIEWER" && ( */}
        <TabPane tab="Gr List" key="2">
          <GrList />
        </TabPane>
        {/* )} */}
      </Tabs>

      {/* <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <h2>Gr List</h2>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <GrList />
        </CardBody>
      </Card> */}
    </React.Fragment>
  );
};

export default withRouter(GrPageHome);
