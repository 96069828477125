import * as React from "react";
// import { useQuery } from 'react-query';
// import { Modal } from 'react-bootstrap';
import CreateChallanForm from "../Form/CreateChallanForm";
import { Modal, Button } from "antd";

export const CreateChallanModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateChallanModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateChallanModalContext.Provider value={{ setIsModalVisible }}>
      <Button onClick={showModal} style={{ display: "inline-flex" }}>
        <i className="ki ki-plus" /> Add Challan
      </Button>
      <Modal
        title="Generate Challan"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
        centered
      >
        <CreateChallanForm />
      </Modal>
    </CreateChallanModalContext.Provider>
  );
};

export default CreateChallanModal;
