import * as React from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  message,
  Tabs,
  Row,
  Col,
  Typography,
} from "antd";
import { request } from "src/utils/services";
import { AuthContext } from "../Context/AuthContext";
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import logo from "../../../assets/img/logo.png";
import {
  LockOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  HeartTwoTone,
} from "@ant-design/icons";
import {
  FormContainer,
  FormTop,
  FormHeader,
  FormDescription,
  FormLogo,
  FormMain,
  FormTitle,
} from "./Form.styled";
import imDigitalLogo from "../../../assets/img/imDigitalLogo.png";
import { isMobile } from "react-device-detect";

const LoginAuth: React.FC = (props) => {
  const authContext = React.useContext(AuthContext);
  const [type, setType] = React.useState("login");
  const [isloading, setLoading] = React.useState(false);

  const onFinish = async (values: any) => {
    const authApi = values.email ? "user.signup" : "user.login";
    setLoading(true);
    try {
      const { payload } = await request(authApi, {}, values);
      setLoading(false);
      if (!isMobile && payload.roles[0] === "ROLE_VIEWER") {
        message.error("User Not Authorised! Contact Administration");
      } else {
        message.success("User Authentication Successfully");
        CookiesService.storeToken(payload.accessToken);
        LocalStorageService.storeAccount(payload.user);
        LocalStorageService.storeRoles(payload.roles);
        CookiesService.storeCompany(payload.profile[0]?.id);
        authContext.setToken(payload.accessToken);
      }
    } catch (e) {
      setLoading(false);
      // console.log('error', e);
      message.error("User Authentication Failed");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row justify={"space-around"} align="middle" style={{ height: "95%" }}>
        <Col span={isMobile ? 24 : 12}>
          {" "}
          <img width={"100%"} src={logo} alt="logo" />
        </Col>
        <Col span={isMobile ? 24 : 12}>
          {" "}
          <FormContainer>
            <FormTop>
              <FormHeader>
                {/* <h2>Welcome to</h2>
              <br />
              <h2>Golden Roadways and Logistics</h2> */}

                <FormTitle>Welcome to Golden Roadways</FormTitle>
              </FormHeader>
              <FormDescription>
                {/* Best Design for Laboratory and Calibration */}
              </FormDescription>
            </FormTop>
            <FormMain>
              <Tabs defaultActiveKey={type} onChange={setType} centered>
                <Tabs.TabPane
                  key="login"
                  tab="Login to Account"
                  className="tab-nav-list"
                >
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username or email!",
                        },
                      ]}
                    >
                      <Input prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <div
                      style={{
                        marginBottom: 24,
                      }}
                    >
                      {/* <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember me</Checkbox>
                    <a
                      style={{
                        float: "right",
                      }}
                      href="!#"
                    >
                      Forgot Password?
                    </a>
                  </Form.Item> */}
                    </div>
                    <Form.Item wrapperCol={{ offset: 0 }}>
                      <Button
                        type="primary"
                        size="large"
                        loading={isloading}
                        htmlType="submit"
                        block
                      >
                        Log in
                      </Button>
                    </Form.Item>
                  </Form>
                </Tabs.TabPane>
              </Tabs>
            </FormMain>
          </FormContainer>
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"}>
        <Typography.Text strong style={{ margin: 5 }}>
          Designed & Developed By{" "}
        </Typography.Text>{" "}
        <img src={imDigitalLogo} width={125} alt="imDigital" />
        <Typography.Text strong style={{ margin: 5 }}>
          with
        </Typography.Text>
        <HeartTwoTone twoToneColor="#eb2f96" style={{ fontSize: 22 }} />
      </Row>
    </>
  );
};

export default LoginAuth;
