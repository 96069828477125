import * as React from "react";
import { Row, Select } from "antd";
// import { request } from 'src/utils/services';
import { useState } from "react";
import { useQuery, useMutation } from "react-query";

import { getGrById, getGr } from "src/modules/GR List/Requests";
import ShowGrCard from "./ShowGrCard";

const GrSearchBox: React.FC = () => {
  const { data: gr } = useQuery(["gr"], async () => getGr(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const [grId, setGrId] = React.useState(0);

  const onGrChange = async (id: number) => {
    setGrId(id);
  };

  return (
    <>
      <Select
        onChange={onGrChange}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option!.children as unknown as string).includes(input)
        }
        filterSort={(optionA, optionB) =>
          (optionA!.children as unknown as string)
            // .toLowerCase()
            .localeCompare(
              optionB!.children as unknown as string
              //.toLowerCase()
            )
        }
        placeholder="Enter GR Number"
        allowClear
        style={{ width: "100%", marginBottom: 20 }}
        // onChange={getInstrumentsParameters}
      >
        {gr &&
          gr?.Gr[0]?.map((item: any, i: number) => {
            // const { id, name } = item?.companyInstruments?.id;
            //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
            return (
              <Select.Option value={item?.id} key={i}>
                {item?.grNumber}
              </Select.Option>
            );
          })}
      </Select>
      <Row>{grId !== 0 && <ShowGrCard id={grId} />}</Row>
    </>
  );
};

export default GrSearchBox;
