import * as React from "react";
import { Form, Input, Button, message, Switch, Select } from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  EditOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManagePartyModalContext } from "../Modal/ManageParty";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { getPartyById } from "../Requests";

// import FormFile from '../../../components/Form/File';

const ManagePartyForm: React.FC<{ id: number }> = (props) => {
  const modalContext = React.useContext(ManagePartyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [editPvtMark, setEditPvtMark] = React.useState(false);
  // const [parameters, setParameters] = React.useState([]);
  const {
    // isLoading,
    // error,
    data: party,
  } = useQuery(["calibrationRequest", props.id], async () =>
    getPartyById(props.id)
  );
  party.Party = party?.Party;
  // console.log(party, "data");
  const ManagePartyMutation = useMutation(
    (values: any) => request("user.party.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("party");
      },
    }
  );

  const onFinish = async (values: any) => {
    values.user_id = LocalStorageService.getAccount().id;
    values.company_id = CookiesService.getCompany();

    setFormLoading(true);

    ManagePartyMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The party has been successfully Managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e ? "Error" : "Pvt Mark Already Exist");
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={party?.Party}
    >
      <Form.Item
        label="Party Name"
        name="name"
        tooltip={{
          title: "Party Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        tooltip={{
          title: "phone",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input maxLength={10} />
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        tooltip={{
          title: "address",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        tooltip={{
          title: "email",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Private Mark"
        name="privateMark"
        tooltip={{
          title: "Private Mark",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        {LocalStorageService.getRoles() === "ROLE_ADMIN" ? (
          <Input
            disabled={!editPvtMark}
            suffix={
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => setEditPvtMark(!editPvtMark)}
              />
            }
          />
        ) : (
          <Input disabled />
        )}
      </Form.Item>
      <Form.Item
        label="GST Number"
        name="gstNumber"
        tooltip={{
          title: "GST Number",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label="Requested By"
        name="name"
        tooltip={{
          title: "Concerned Person Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      > */}
      {/* <Select placeholder="Requested By" allowClear>
          {console.log(companyData, "companyData")};
          {companyData?.concernedPerson &&
            companyData?.concernedPerson?.map((item: any, i: number) => {
              // const { id, name } = item?.companyInstruments?.id;
              //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
              return (
                <Select.Option value={item?.name} key={i}>
                  {item?.name}
                </Select.Option>
              );
            })}
        </Select> */}
      {/* </Form.Item> */}
      {/* <Form.Item
        label="Remarks"
        name="remarks"
        tooltip={{
          title: "Description/ Remarks",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[{ required: true }]}
      >
        <Input.TextArea
          placeholder="Enter Description/Remarks"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item> */}
      {/* <Form.Item
                label="Logo"
                name="avatar"
                tooltip={{
                    title: 'Payment Method Icon',
                    icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
            >
                <FormFile />
            </Form.Item> */}
      {/* <Form.Item
                label="Status"
                name="isactive"
                tooltip={{
                    title: 'Display to user',
                    icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
            >
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                />
            </Form.Item> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Party
        </Button>
      </Form.Item>
    </Form>
    // <Form
    //   layout="vertical"
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
    //   initialValues={party?.Party}
    // >
    //   <Form.Item
    //     label="Instruments"
    //     name="instruments"
    //     tooltip={{
    //       title: "Instrument",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select placeholder="Instruments" onChange={getInstrumentsParameters}>
    //       {instrumentsData &&
    //         instrumentsData?.companyInstruments?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.id} key={i}>
    //               {item?.title}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>
    //   <Form.Item
    //     label="Parameters"
    //     name="parameters"
    //     tooltip={{
    //       title: "Parameters",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select
    //       //mode="multiple"
    //       placeholder="Parameters"
    //       allowClear
    //     >
    //       {console.log(parameters, "parameters")};
    //       {parameters &&
    //         parameters?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.title} key={i}>
    //               {item?.title}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>

    //   <Form.Item
    //     label="Requested By"
    //     name="name"
    //     tooltip={{
    //       title: "Concerned Person Name",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     rules={[{ required: true }]}
    //   >
    //     {/* <Select placeholder="Requested By" allowClear>
    //       {console.log(companyData, "companyData")};
    //       {companyData?.concernedPerson &&
    //         companyData?.concernedPerson?.map((item: any, i: number) => {
    //           // const { id, name } = item?.companyInstruments?.id;
    //           //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
    //           return (
    //             <Select.Option value={item?.name} key={i}>
    //               {item?.name}
    //             </Select.Option>
    //           );
    //         })}
    //     </Select> */}
    //   </Form.Item>
    //   <Form.Item
    //     label="Remarks"
    //     name="remarks"
    //     tooltip={{
    //       title: "Description/ Remarks",
    //       icon: <InfoCircleOutlined />,
    //     }}
    //     // rules={[{ required: true }]}
    //   >
    //     <Input.TextArea
    //       placeholder="Enter Description/Remarks"
    //       autoSize={{ minRows: 3, maxRows: 5 }}
    //     />
    //   </Form.Item>
    //   {/* <Form.Item
    //             label="Logo"
    //             name="avatar"
    //             tooltip={{
    //                 title: 'Payment Method Icon',
    //                 icon: <InfoCircleOutlined />,
    //             }}
    //             rules={[{ required: true }]}
    //         >
    //             <FormFile />
    //         </Form.Item> */}
    //   {/* <Form.Item
    //             label="Status"
    //             name="isactive"
    //             tooltip={{
    //                 title: 'Display to user',
    //                 icon: <InfoCircleOutlined />,
    //             }}
    //             valuePropName="checked"
    //         >
    //             <Switch
    //                 checkedChildren="Active"
    //                 unCheckedChildren="Inactive"
    //                 defaultChecked
    //             />
    //         </Form.Item> */}

    //   <Form.Item style={{ textAlign: "center" }}>
    //     <Button
    //       size="large"
    //       loading={formLoading}
    //       type="primary"
    //       htmlType="submit"
    //       shape="round"
    //     >
    //       Submit
    //     </Button>
    //   </Form.Item>
    // </Form>
  );
};

export default ManagePartyForm;
