import { Avatar, Tag } from "antd";
import moment from "moment";
import * as React from "react";
import SVG from "react-inlinesvg";
// import { request } from 'src/utils/services';
import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import { Datatable } from "../../../../components/Datatable";
import UsersManageModal from "../../Modal/ManageUsers";
import { getUsers } from "../../Requests";
//import { getInstrumentById } from 'src/modules/Instruments/Requests';
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { Button, Tooltip, Space, Input, Table } from "antd";
//import UsersViewModal from "../../Modal/ShowUsersDetails";
import {
  EyeOutlined,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";

const UsersList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<Input>(null);
  // console.log(CookiesService.getToken(), "token")
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["party", LocalStorageService.getAccount().id, page, size],
    () => getUsers(),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
      keepPreviousData: true,
      staleTime: 5000,
      // refetchOnMount: false,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const column = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      //render: (text: any) => moment(text).format("D MMM y"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",

      render: (_: any, data: any) => (
        //getInstrumentById(id);
        <p>{data.roles[0].name}</p>
      ),
    },

    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (id: any) => <h2>********</h2>,
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <UsersViewModal id={id} /> */}
          <UsersManageModal id={id} />
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response?.user} />;
};

export default UsersList;
