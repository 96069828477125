import * as React from "react";
import { Button, Col, Row } from "antd";
// import { request } from 'src/utils/services';
import { useQuery } from "react-query";
import { getGatepassById } from "../Requests";
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { queryClient } from 'src';
// import FormFile from '../../../components/Form/File';
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
//import { ShowGatepassDetailsModalContext } from '../Modal/ShowGatepassDetails';
// import Item from 'antd/lib/list/Item';
import moment from "moment";
import { getProfileByUser } from "src/modules/Profile/Requests";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import LocalStorageService from "src/utils/local-storage";
//import { getInstrumentById, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import {
  GatePassWrapper,
  GatePassBody,
  GatePassHeader,
  GatePassSubHeader,
  GatePassGSTIN,
  PrintableBodyWrapper,
  GatePassName,
  GatePassTableWrapper,
} from "./GatePass.styled";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import ShowGatepassDetailsForm from "./ShowGatePassForm";
import { useReactToPrint } from "react-to-print";
import { isMobile } from "react-device-detect";

interface MatchParams {
  id: number;
}
// class componentToPrint extends React.Component {
//   render() {
//     return <ShowGatepassDetailsForm id={2} />;
//   }
// }
const Gatepass: React.FC<RouteComponentProps> = (props) => {
  //const [parameters, setParameters] = React.useState([]);
  const location = useLocation();
  let gid: string = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const { push } = useHistory();
  // const {
  //   isLoading,
  //   error,
  //   data: Gatepass,
  // } = useQuery(["GatepassRequest", gid], async () => getGatepassById(+gid));
  // const {
  //   //isLoading,
  //   //error,
  //   data: profile,
  // } = useQuery(["profile", LocalStorageService.getAccount().id], async () =>
  //   getProfileByUser(LocalStorageService.getAccount().id)
  // );

  const PrintDocument = () => {
    // console.log("Print");
    // print('myDiv', 'html');
    window.print();
  };
  // if (isLoading || error) {
  //   return <div>Wrong</div>;
  // }
  const componentRef = React.useRef();
  //const handlePrint = useReactToPrint({});

  return (
    <>
      {/* <div>
        <ShowGatepassDetailsForm id={+gid} />
      </div> */}
      {/* <PrintableBodyWrapper>
        <Button
          className="no-print"
          onClick={() => push("/gatepass")}
          style={{ margin: "2rem" }}
        >
          Back
        </Button>
        <Button className="no-print" type="primary" onClick={PrintDocument}>
          Print Now
        </Button>

        <GatePassWrapper>
          <GatePassHeader>
            {" "}
            <img src={logo} alt="logo" style={{ width: "200px" }} />
            
          </GatePassHeader>
          <GatePassSubHeader>{profile?.Profile?.address}</GatePassSubHeader>
          <GatePassGSTIN>GSTIN:{profile?.Profile?.gstNumber}</GatePassGSTIN>
          <GatePassBody>
            <table>
              <tr>
                <th>Date:</th>
                <td style={{ textAlign: "start" }}>
                  {" "}
                  {moment(Gatepass.Gatepass[0][0]?.gatePassDate).format(
                    "DD MMM y"
                  )}
                  {"   "}
                </td>
              

                <th>Station: Patna</th>
              </tr>
            </table>
            
          </GatePassBody>
          <table>
            <tr>
              <th style={{ padding: "5px 1px 1px 10px", textAlign: "start" }}>
                {" "}
                GST No:
              </th>
            </tr>
          </table>
          <table>
            <tr>
              <th style={{ padding: "10px", textAlign: "start" }}>
                {" "}
                Received with Thanks:
              </th>
            </tr>
          </table>

        
          <GatePassTableWrapper>
            <table>
              <thead>
                <tr>
                  <th>GR. No</th>
                  <th>Qty</th>
                  <th>Weight</th>
                  <th>Invoice Date</th>
                  <th>Invoice No.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{Gatepass.Gatepass[0][0]?.grNumber}</td>
                  <td>{Gatepass.Gatepass[0][0]?.quantity}</td>
                  <td>{Gatepass.Gatepass[0][0]?.weight}</td>
                  <td>-</td>
                  <td>-</td>
                </tr>

                <tr>
                  <th>Content</th>
                  <td colSpan={2} style={{ textAlign: "start" }}>
                    {Gatepass.Gatepass[0][0]?.content}
                  </td>
                  <th>Private Mark</th>
                  <td style={{ textAlign: "start" }}>
                    {Gatepass.Gatepass[0][0]?.privateMark}
                  </td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Freight
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.freightAmount}</td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Bilty Charge
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.biltyCharge}</td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Gate Pass Charge
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.gatepassCharge}</td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Door Delivery Charge
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.doorDeliveryCharge}</td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Misc. Charge
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.miscCharge}</td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Rebate(-)
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.rebate}</td>
                </tr>

                <tr>
                  <th colSpan={4} style={{ textAlign: "end" }}>
                    Total Amount
                  </th>
                  <td>{Gatepass.Gatepass[0][0]?.totalAmount}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    Thanks!
                    <p className="m0">* Daily Parcel Service</p>
                    <p className="m0">* 24 *7 Service Provider</p>
                  </td>
                  <td style={{ textAlign: "start" }}>Signature</td>
                </tr>
              </tfoot>
            </table>
          </GatePassTableWrapper>
        </GatePassWrapper>
      </PrintableBodyWrapper> */}
      <PrintableBodyWrapper>
        <div style={{ margin: "0 1rem 0 1rem" }}>
          <ShowGatepassDetailsForm id={+gid} />
        </div>

        <Row>
          <Col span={12} offset={10}>
            <Button
              className="no-print"
              type="primary"
              onClick={PrintDocument}
              style={{ margin: "1rem" }}
            >
              Print Now
            </Button>
            <Button
              className="no-print"
              onClick={() => push("/gatepass")}
              style={{ margin: "1rem" }}
            >
              Back
            </Button>
          </Col>
        </Row>
      </PrintableBodyWrapper>
    </>
  );
};

export default withRouter(Gatepass);
