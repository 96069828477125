import * as React from "react";

import { Modal, Button, Tooltip } from "antd";
//import ShowChallanDetailsForm from "../Form/ShowChallanDetailsForm";
import {
  EyeOutlined,
  NotificationOutlined,
  ShoppingCartOutlined,

  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import UnloadingForm from "../Form/UnloadingForm";
export const UnloadingModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const UnloadingChallanModal: React.FC<{
  id: number;
  title: string;
  type?: any;
}> = ({ id, title, type }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <UnloadingModalContext.Provider value={{ setIsModalVisible }}>
      {/* <Tooltip title="View All Details"> */}
      <Button
        onClick={showModal}
        type={type ? type : "primary"}
        //shape="circle"
        icon={<ShoppingCartOutlined />}
      >
        {title}
      </Button>
      {/* </Tooltip> */}
      <Modal
        width={300}
        footer={null}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <h1>Edit Challan</h1> */}
        <UnloadingForm id={id} />
      </Modal>
    </UnloadingModalContext.Provider>
  );
};

export default UnloadingChallanModal;
