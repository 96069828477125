import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Divider,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageGatepassModalContext } from "../Modal/ManageGatePass";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';
import { getGatepassById } from "../Requests";

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { values } from "lodash";
import { getParty } from "src/modules/Party/Requests";
import { getChallan } from "src/modules/Challan/Requests";
import ManageChallanModal from "src/modules/Challan/Modal/ManageChallan";
import { getGr, getGrById, getGrGatepass } from "src/modules/GR List/Requests";
import { getDriver } from "src/modules/Driver/Requests";
import ShowGrCard from "../../GR List/Component/Home/ShowGrCard";
import moment from "moment";
import GrList from "src/modules/GR List/Component/Home/List";
import { render } from "react-dom";

// import FormFile from '../../../components/Form/File';

const ManageGatepassForm: React.FC<{ id: number }> = (props) => {
  const modalContext = React.useContext(ManageGatepassModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [parameters, setParameters] = React.useState([]);
  const [grData, setGrData] = React.useState<[object] | any>(undefined);

  // const [freightAmount, setFreightAmount] = React.useState(0);
  // const [gatepassCharge, setGatepassCharge] = React.useState(0);
  // const [biltyCharge, setBiltyCharge] = React.useState(0);

  // const [doorDeliveryCharge, setDoorDeliveryCharge] = React.useState(0);
  // const [rebate, setRebate] = React.useState(0);
  // const [miscCharge, setMiscCharge] = React.useState(0);
  // const [totalAmount, setTotalAmount] = React.useState(0);
  // const [quantity, setQuantity] = React.useState(0);
  // const [weight, setWeight] = React.useState(0);
  // const [challanId, setChallanId] = React.useState(0);
  // const [partyId, setPartyId] = React.useState(0);

  const ManageGatepassMutation = useMutation(
    (values: any) => request("user.gatepass.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("gatepass");
        queryClient.invalidateQueries("gr");
        queryClient.invalidateQueries("grforgatepass");
      },
    }
  );
  const {
    // isLoading,
    // error,
    data: gatepass,
  } = useQuery(
    ["calibrationRequest", props.id],
    async () => getGatepassById(props.id),
    {}
  );
  const [freightAmount, setFreightAmount] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.freightAmount)
  );
  const [gatepassCharge, setGatepassCharge] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.gatepassCharge)
  );
  const [biltyCharge, setBiltyCharge] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.biltyCharge)
  );

  const [doorDeliveryCharge, setDoorDeliveryCharge] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.doorDeliveryCharge)
  );
  const [rebate, setRebate] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.rebate)
  );
  const [miscCharge, setMiscCharge] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.miscCharge)
  );
  const [totalAmount, setTotalAmount] = React.useState(
    parseFloat(gatepass.Gatepass[0][0]?.totalAmount)
  );
  const [quantity, setQuantity] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [challanId, setChallanId] = React.useState(
    gatepass?.Gatepass[0][0].challanId
  );
  const [partyId, setPartyId] = React.useState(
    gatepass?.Gatepass[0][0].partyId
  );

  const {
    // isLoading,
    data: party,
    // error,
    // isError,
  } = useQuery(["party"], async () => getParty(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const {
    // isLoading,
    // isLoading,
    // isError,
    data: challan,

    // isError,
  } = useQuery(["challan"], async () => getChallan(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const {
    // isLoading,
    //isLoading,
    //isError,
    data: gr,

    // isError,
  } = useQuery(["gr"], async () => getGr(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const {
    // isLoading,
    //isLoading,
    //isError,
    data: driver,

    // isError,
  } = useQuery(["driver"], async () => getDriver(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const mutateGR = useMutation((id: number) => getGrById(id));
  const [grId, setGrId] = React.useState(0);
  // Get Parameters Function
  const onGrChange = async (id: number) => {
    setGrId(id);
    setGrData(gr?.Gr[0].filter((gr: any) => gr.id === id));

    mutateGR
      .mutateAsync(id)
      .then((payload: any) => {
        setQuantity(payload?.Gr.quantity);
        setWeight(payload?.Gr.weight);
        // setFreightAmount(payload?.Gr.toPayAmount);
        setChallanId(payload?.Gr[0][0].challanId);
        setPartyId(payload?.Gr[0][0].partyId);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    console.log(challanId);

    getTotalAmount();
    values.totalAmount = totalAmount;
    values.challanId = challanId;
    values.partyId = partyId;

    setFormLoading(true);

    ManageGatepassMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The gatepass has been successfully Managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
        setTotalAmount(0);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
    values.gatePassDate = moment(values.gatePassDate).format("YYYY-MM-DD");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  //     const { Option } = Select;

  // const children = [];
  // for (let i = 10; i < 36; i++) {
  //   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  // }

  // function handleChange(value) {
  //   console.log(`selected ${value}`);
  // }
  const getTotalAmount = () => {
    let tAmt =
      freightAmount +
      gatepassCharge +
      biltyCharge +
      doorDeliveryCharge +
      miscCharge -
      rebate;
    setTotalAmount(tAmt);
  };
  //const dateFormatList = ["DD/MM/YYYY"];

  // const onGrChange = (values: any) => {

  // }
  //  challan.challan.challanDate = moment(
  //   challan?.challan?.challanDate,
  //   "YYYY-MM-DD"
  // );
  gatepass.GP = gatepass?.Gatepass[0][0];
  gatepass.GP.gatePassDate = moment(gatepass.GP.gatePassDate, "YYYY-MM-DD");
  gatepass.GP.invoiceDate = moment(gatepass.GP.invoiceDate, "YYYY-MM-DD");

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={gatepass.GP}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={10}>
        <Col style={{ width: "75%" }}>
          <Row gutter={20}>
            <Col flex={1}>
              <Form.Item
                label="Gate Pass Date"
                name="gatePassDate"
                tooltip={{
                  title: "Gatepass Date",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <DatePicker format={"DD-MM-YY"} />
                {/* <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="GR Number"
                name="grId"
                tooltip={{
                  title: "GR",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  // {
                  //   type: "email",
                  //   message: "The input is not valid E-mail!",
                  // },
                  {
                    required: true,
                    //message: "Please input your E-mail!",
                  },
                ]}
              >
                <Select
                  onChange={onGrChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      // .toLowerCase()
                      .localeCompare(
                        optionB!.children as unknown as string
                        //.toLowerCase()
                      )
                  }
                  placeholder="Select GR Number"

                  // onChange={getInstrumentsParameters}
                >
                  {gr &&
                    gr?.Gr[0]?.map((item: any, i: number) => {
                      // const { id, name } = item?.companyInstruments?.id;
                      //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
                      return (
                        <Select.Option value={item?.id} key={i}>
                          {item?.grNumber}
                        </Select.Option>
                      );
                    })}
                </Select>
                {/* <Input
              addonBefore={<MailOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Quantity"
                name="quantity"
                tooltip={{
                  title: "Quantity",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
                {/* <Input
              addonBefore={<IdcardOutlined className="site-form-item-icon" />}
              maxLength={20}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Weight"
                name="weight"
                tooltip={{
                  title: "Weight",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
                {/* <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col flex={1}>
              <Form.Item
                label="Freight Amount"
                name="freightAmount"
                tooltip={{
                  title: "Freight Amount",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  // defaultValue={freightAmount}
                  onChange={(e: any) => {
                    setFreightAmount(parseFloat(e.target.value));
                  }}
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Invoice Date"
                name="invoiceDate"
                tooltip={{
                  title: "Invoice Date",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <DatePicker format={"DD-MM-YY"} />
                {/* <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            /> */}
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Invoice Number"
                name="invoiceNumber"
                tooltip={{
                  title: "Invoice Number",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Fixed Amount"
                name="miscCharge"
                tooltip={{
                  title: "Miscellaneous Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setMiscCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <Divider /> */}
          <Row gutter={20}>
            <Col flex={2}>
              <Form.Item
                label="Gate Pass Charge"
                name="gatepassCharge"
                tooltip={{
                  title: "GatePass Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setGatepassCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>

            <Col flex={2}>
              <Form.Item
                label="Bilty Charge"
                name="biltyCharge"
                tooltip={{
                  title: "Bilty Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setBiltyCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
            <Col flex={2}>
              <Form.Item
                label="Door Delivery Charge"
                name="doorDeliveryCharge"
                tooltip={{
                  title: "Door Delivery Charge",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    setDoorDeliveryCharge(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
            <Col flex={2}>
              <Form.Item
                label="Rebate"
                name="rebate"
                tooltip={{
                  title: "Rebate",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input
                  onChange={(e: any) => {
                    //console.log(e, "e");
                    // console.log(parseFloat(e.target.value), "f");
                    setRebate(parseFloat(e.target.value));
                  }}
                  // addonBefore={
                  //   <EnvironmentOutlined className="site-form-item-icon" />
                  // }
                  //maxLength={8}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col flex={1}>
              <Form.Item
                label="Assigned to "
                name="driverId"
                tooltip={{
                  title: "Driver Name",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      // .toLowerCase()
                      .localeCompare(
                        optionB!.children as unknown as string
                        //.toLowerCase()
                      )
                  }
                  placeholder="Select Assigned Person"

                  // onChange={getInstrumentsParameters}
                >
                  {driver &&
                    driver?.Driver?.map((item: any, i: number) => {
                      // const { id, name } = item?.companyInstruments?.id;
                      //const [instrumentParameterData, setInstrumentParameterData] = useState(['item?.companyInstruments?.id']);
                      return (
                        <Select.Option value={item?.id} key={i}>
                          {item?.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col flex={2}>
              <Form.Item
                label="Received With Thanks"
                name="deliveredTo"
                tooltip={{
                  title: "Delivered To",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Input />
                {/* <Input
              addonBefore={<IdcardOutlined className="site-form-item-icon" />}
              maxLength={20}
            /> */}
              </Form.Item>
            </Col>

            {/* <Col flex={2}>
              <Form.Item
                label="Status"
                name="grStatus"
                tooltip={{
                  title: "status of GR",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }]}
              >
                <Select placeholder="Select Status">
                  <Select.Option value="Partial Dispatch">
                    Partial Dispatch
                  </Select.Option>
                  <Select.Option value="Dispatched">Dispatched</Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col flex={4}>
              <Descriptions
                //title="Responsive Descriptions"
                bordered
                column={4}
              >
                <Descriptions.Item label="Total Amount">
                  {totalAmount}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col flex={1}>
              <Form.Item name="totalAmount" style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  // loading={formLoading}
                  type="primary"
                  //htmlType="submit"
                  //shape="round"
                  onClick={() => getTotalAmount()}
                >
                  Get Total
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              size="large"
              loading={formLoading}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
        <Col style={{ width: "25%" }}>
          {grId !== 0 ? <ShowGrCard id={grId} /> : <Empty />}
        </Col>
      </Row>
    </Form>
  );
};

export default ManageGatepassForm;
