import { Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import PartyList from "../Component/Home/List";
import CreatePartyModal from "../Modal/CreateParty";

const PartyPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Party List</h2>
              <CreatePartyModal />
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PartyList />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(PartyPageHome);
