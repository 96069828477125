import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Image,
  Form,
  Card,
  Space,
} from "antd";
// import { request } from 'src/utils/services';
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { getGatepassById } from "../Requests";
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { queryClient } from 'src';
// import FormFile from '../../../components/Form/File';
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
//import { ShowGatepassDetailsModalContext } from '../Modal/ShowGatepassDetails';
// import Item from 'antd/lib/list/Item';
import logo from "../../../assets/img/logo.png";
import moment from "moment";
import SVG from "react-inlinesvg";
import { Datatable } from "../../../components/Datatable";
import { getProfileByUser } from "src/modules/Profile/Requests";
import LocalStorageService from "src/utils/local-storage";
import { getChallanById } from "src/modules/Challan/Requests";
import { eachRight } from "lodash";
//import { getInstrumentById, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';

const { Step } = Steps;

const ShowGatepassDetailsForm: React.FC<{ id: number }> = (props) => {
  //const [parameters, setParameters] = React.useState([]);

  const { isLoading, error, data } = useQuery(
    ["GatepassRequest", props.id],
    async () => getGatepassById(props.id)
  );
  const {
    //isLoading,
    //error,
    data: profile,
  } = useQuery(["profile", LocalStorageService.getAccount().id], async () =>
    getProfileByUser(LocalStorageService.getAccount().id)
  );
  const {
    //isLoading,
    //error,
    data: challan,
  } = useQuery(["challan", LocalStorageService.getAccount().id], async () =>
    getChallanById(LocalStorageService.getAccount().id)
  );

  return (
    //<h1> {Instrument?.payload?.status}</h1>
    <>
      <Card>
        <Row gutter={16} justify="space-between">
          <Col span={8}>
            {" "}
            <Image src={logo} />
          </Col>
          <Col span={16}>
            <div>
              <h3>{profile.Profile.companyName}</h3>
              <h5>{"Add: " + profile.Profile.address}</h5>
              <h5>
                {"Mob: " +
                  profile.Profile.mobile +
                  "," +
                  profile?.Profile?.altMobile +
                  " Email: " +
                  profile.Profile.email}
              </h5>
              <h5>{"GSTIN: " + profile.Profile.gstNumber}</h5>
            </div>
          </Col>
        </Row>
        <Divider>GATE-PASS</Divider>
        <Row gutter={20}>
          {" "}
          <Descriptions size={"small"} column={3}>
            <Descriptions.Item
              label="Received Withs Thanks"
              span={2}
              labelStyle={{ fontWeight: "bold" }}
              contentStyle={{
                //borderRadius: "10px",
                fontWeight: "bold",
                fontStyle: "italic",
                //fontVariant:''
                //fontSize: "0.8rem",
                fontOpticalSizing: "auto",
              }}
            >
              {data?.Gatepass[0][0]?.deliveredTo}
            </Descriptions.Item>
            <Descriptions.Item
              // contentStyle={{ width: "67%", textAlign: "start" }}
              label="GP No"
              span={2}
              labelStyle={{ fontWeight: "bold" }}
            >
              GRGP-{data?.Gatepass[0][0]?.id}
            </Descriptions.Item>
          </Descriptions>
        </Row>

        {/* <Divider /> */}
        <Row gutter={20}>
          <Descriptions column={3} size="small">
            <Descriptions.Item
              // contentStyle={{ width: "67%", textAlign: "start" }}
              label="GSTIN"
              span={2}
              labelStyle={{ fontWeight: "bold" }}
            >
              {data?.Gatepass[0][0]?.gstNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label="Date "
              labelStyle={{ fontWeight: "bold" }}
            >
              {moment(data?.Gatepass[0][0]?.gatePassDate).format("DD MMM y")}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        {/* <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="GR Number"
              value={moment(data?.Gatepass[0][0]?.gatePassDate).format(
                "DD MMM y"
              )}
            />
          </Col>
        </Row> */}

        {/* <Divider orientation="left"></Divider> */}
        <Descriptions layout="vertical" bordered column={4} size="small">
          <Descriptions.Item label="GR No.">
            {data?.Gatepass[0][0]?.grNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Content">
            {data?.Gatepass[0][0]?.content}
          </Descriptions.Item>
          <Descriptions.Item label="Qty.">
            {data?.Gatepass[0][0]?.quantity}
          </Descriptions.Item>
          <Descriptions.Item label="Weight">
            {data?.Gatepass[0][0]?.weight}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical" bordered size="small" column={4}>
          {/* <Descriptions.Item label="Content" labelStyle={{ width: "25%" }}>
            {data?.Gatepass[0][0]?.content}
          </Descriptions.Item> */}
          <Descriptions.Item
            label="Invoice No. "
            // labelStyle={{ fontWeight: "bold" }}
          >
            {data?.Gatepass[0][0]?.invoiceNumber}
          </Descriptions.Item>
          <Descriptions.Item
            label="Invoice Date "
            // labelStyle={{ fontWeight: "bold" }}
          >
            {moment(data?.Gatepass[0][0]?.invoiceDate).format("DD MMM y")}
          </Descriptions.Item>
          <Descriptions.Item
            label="Private Mark"
            contentStyle={{
              fontWeight: "bold",
              fontStyle: "italic",
              fontOpticalSizing: "auto",
            }}
          >
            {data?.Gatepass[0][0]?.privateMark}
          </Descriptions.Item>
          {/* <Descriptions.Item> </Descriptions.Item> */}
        </Descriptions>
        <Descriptions column={1} bordered size="small">
          <Descriptions.Item
            label="Frieght Amount"
            labelStyle={{ width: "67%", textAlign: "end" }}
          >
            ₹ {data?.Gatepass[0][0]?.freightAmount}
          </Descriptions.Item>
          <Descriptions.Item
            label="Fixed Amount"
            labelStyle={{ width: "67%", textAlign: "end" }}
          >
            ₹ {data?.Gatepass[0][0]?.miscCharge}
          </Descriptions.Item>
          <Descriptions.Item
            label="Bilty Charge"
            labelStyle={{ width: "67%", textAlign: "end" }}
          >
            ₹ {data?.Gatepass[0][0]?.biltyCharge}
          </Descriptions.Item>
          <Descriptions.Item
            label="GatePass Charge"
            labelStyle={{ width: "67%", textAlign: "end" }}
          >
            ₹ {data?.Gatepass[0][0]?.gatepassCharge}
          </Descriptions.Item>
          <Descriptions.Item
            label="Door Delivery Charge"
            labelStyle={{ width: "67%", textAlign: "end" }}
          >
            ₹ {data?.Gatepass[0][0]?.doorDeliveryCharge}
          </Descriptions.Item>

          <Descriptions.Item
            label="Rebate(-)"
            labelStyle={{ width: "67%", textAlign: "end" }}
          >
            ₹ {data?.Gatepass[0][0]?.rebate}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Amount"
            labelStyle={{
              width: "67%",
              textAlign: "end",
              fontWeight: "bold",
            }}
            contentStyle={{
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            ₹ {data?.Gatepass[0][0]?.totalAmount}
          </Descriptions.Item>
          {/* <Descriptions.Item> </Descriptions.Item> */}
        </Descriptions>
        <div style={{ marginTop: "2rem" }}>
          <Row>
            <Col span={6} offset={18}>
              Signature
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default ShowGatepassDetailsForm;
