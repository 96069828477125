import * as React from "react";
// import { useQuery } from 'react-query';
// import { Modal } from 'react-bootstrap';
import CreateDriverForm from "../Form/CreateDriverForm";
import { Modal, Button } from "antd";
import { DownloadOutlined, UsergroupAddOutlined } from "@ant-design/icons";

export const CreateDriverModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateDriverModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateDriverModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        //style={{ display: "inline-flex" }}
        type="primary"
        //ghost
        shape="round"
        icon={<UsergroupAddOutlined />}
      >
        {/* <i className="ki ki-plus" /> */}
        Add Assigned Person
      </Button>
      <Modal
        title="Add Assigned Person"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateDriverForm />
      </Modal>
    </CreateDriverModalContext.Provider>
  );
};

export default CreateDriverModal;
