export default class LocalStorageService {
  /**
   * @type {string}
   */
  public static LOCAL_STORAGE_AUTH_TOKEN = "lims_token";
  public static LOCAL_STORAGE_ACCOUNT = "lims_account";
  public static LOCAL_STORAGE_COMPANY = "lims_company";
  public static LOCAL_STORAGE_ROLES = "lims_roles";

  static isAuthenticated(): boolean {
    return LocalStorageService.getToken() !== null;
  }

  /**
   * Store package
   * @param {string} token
   * @returns {void}
   */
  public static storeToken(token: string) {
    localStorage.setItem(LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN, token);
  }

  /**
   * Store token
   * @returns {string}
   */
  public static getToken(): string {
    return localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN
    ) as string;
  }

  /**
   * Remove token
   * @returns {string}
   */
  public static removeToken(): void {
    return localStorage.removeItem(
      LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN
    );
  }

  /**
   * Remove account
   * @returns {string}
   */
  public static removeAccount(): void {
    return localStorage.removeItem(LocalStorageService.LOCAL_STORAGE_ACCOUNT);
  }

  /**
   * Store account
   * @param {object} accountData
   * @returns void
   */
  public static storeAccount(accountData: object) {
    localStorage.setItem(
      LocalStorageService.LOCAL_STORAGE_ACCOUNT,
      JSON.stringify(accountData)
    );
  }

  /**
   * Get account
   * @returns {object}
   */
  public static getAccount(): any {
    const accountItem = localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_ACCOUNT
    );
    if (accountItem) {
      return JSON.parse(accountItem);
    }
  }
  /**
   * Store Company
   * @param {string} company
   * @returns {void}
   */
  public static storeCompany(company: string) {
    localStorage.setItem(LocalStorageService.LOCAL_STORAGE_COMPANY, company);
  }

  /**
   * Store company
   * @returns {string}
   */
  public static getCompany(): string {
    return localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_COMPANY
    ) as string;
  }

  /**
   * Remove company
   * @returns {string}
   */
  public static removeCompany(): void {
    return localStorage.removeItem(LocalStorageService.LOCAL_STORAGE_COMPANY);
  }

  /**
   * Store Roles
   * @param {string} roles
   * @returns {void}
   */
  public static storeRoles(roles: string) {
    localStorage.setItem(LocalStorageService.LOCAL_STORAGE_ROLES, roles);
  }

  /**
   * Store company
   * @returns {string}
   */
  public static getRoles(): string {
    return localStorage.getItem(
      LocalStorageService.LOCAL_STORAGE_ROLES
    ) as string;
  }

  /**
   * Remove company
   * @returns {string}
   */
  public static removeRoles(): void {
    return localStorage.removeItem(LocalStorageService.LOCAL_STORAGE_ROLES);
  }
}
