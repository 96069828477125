import { Tabs } from "antd";
import { size } from "lodash";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import ChallanList from "../Component/Home/List";
import CreateChallanForm from "../Form/CreateChallanForm";
//import CreateChallanModal from "../Modal/CreateChallan";
const { TabPane } = Tabs;

const ChallanPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }}>
        <TabPane tab="Add Challan" key="1">
          <CreateChallanForm />
        </TabPane>
        <TabPane tab="Challan List" key="2">
          <ChallanList />
        </TabPane>
      </Tabs>
      {/* <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <h2>Challan List</h2>
            <CreateChallanModal />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ChallanList />
        </CardBody>
      </Card> */}
    </React.Fragment>
  );
};

export default withRouter(ChallanPageHome);
