import { TRoute } from "../../utils/routes";
import GrPageHome from "./Page/Home";

const GrRouter: TRoute[] = [
  {
    path: "/gr",
    name: "gr",
    title: "Gr",
    component: GrPageHome,
  },
];

export default GrRouter;
