import { Tabs } from "antd";
import * as React from "react";
//import { TabPane } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import GatepassList from "../Component/Home/List";
import CreateGatepassForm from "../Form/CreateGatePassForm";

//import CreateGatepassModal from "../Modal/CreateGatePass";
const { TabPane } = Tabs;

function GatepassPageHome() {
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }}>
        <TabPane tab="Add GatePass" key="1">
          <CreateGatepassForm />
        </TabPane>

        <TabPane tab="GatePass List" key="2">
          <GatepassList />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default withRouter(GatepassPageHome);
