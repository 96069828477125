import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'classnames';
import { isFragment } from 'react-is';

export const CardHeaderIcon = forwardRef<any, { className?: string }>(
    ({ className }, ref) => (
        <span ref={ref} className={clsx('card-head-icon', className)} />
    )
);

export const CardHeaderTitle = forwardRef<any, any>(
    ({ children, title, ...props }, ref) => (
        <React.Fragment ref={ref} {...props}>
            {title && (
                <div className="card-title align-items-start flex-column">
                    <h3 className={'card-label'}>{title}</h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        {children}
                    </span>
                </div>
            )}
            {!title && <h3 className={'card-label'}>{children}</h3>}
        </React.Fragment>
    )
);

export const CardHeaderToolbar = forwardRef<any, any>(
    ({ children, className, ...props }, ref) => (
        <div {...props} ref={ref} className={clsx('card-toolbar', className)}>
            {children}
        </div>
    )
);

export const CardHeader = forwardRef<any, any>(
    (
        {
            children,
            icon,
            title,
            toolbar,
            className,
            sticky = false,
            labelRef,
            ...props
        },
        ref
    ) => {
        const [top, setTop] = useState(0);
        const [windowHeight, setWindowHeight] = useState(0);

        useEffect(() => {
            handleResize();

            function handleResize() {
                setWindowHeight(window.innerWidth);
            }

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        });

        useEffect(() => {
            // Skip if sticky is disabled or on initial render when we don't know about window height.
            if (!sticky || windowHeight === 0) {
                return;
            }

            const headerElement = document.querySelector('.header');
            const subheaderElement = document.querySelector('.subheader');
            const headerMobileElement =
                document.querySelector('.header-mobile');

            if (
                headerElement === null ||
                headerMobileElement === null ||
                subheaderElement === null
            ) {
                return;
            }
            let nextMarginTop = 0;

            if (
                headerElement instanceof HTMLElement &&
                headerMobileElement instanceof HTMLElement &&
                subheaderElement instanceof HTMLElement
            ) {
                if (window.getComputedStyle(headerElement).height === '0px') {
                    nextMarginTop = headerMobileElement.offsetHeight;
                } else {
                    // desktop header
                    if (
                        document.body.classList.contains(
                            'header-minimize-topbar'
                        )
                    ) {
                        // hardcoded minimized header height
                        nextMarginTop = 60;
                    } else {
                        // normal fixed header
                        if (document.body.classList.contains('header-fixed')) {
                            nextMarginTop += headerElement.offsetHeight;
                        }

                        if (
                            document.body.classList.contains('subheader-fixed')
                        ) {
                            nextMarginTop += subheaderElement.offsetHeight;
                        }
                    }
                }
            }

            setTop(nextMarginTop);
        }, [sticky, windowHeight]);

        return (
            <div
                ref={ref}
                className="card-header py-3"
                style={
                    !sticky
                        ? undefined
                        : { top, position: 'sticky', backgroundColor: '#fff' }
                }
            >
                {title != null && (
                    <div
                        ref={labelRef}
                        className={clsx('card-title', className)}
                    >
                        {icon}

                        {typeof title === 'string' || isFragment(title) ? (
                            <CardHeaderTitle title={title} />
                        ) : (
                            title
                        )}
                    </div>
                )}

                {toolbar}
                {children}
            </div>
        );
    }
);

export const CardBody = forwardRef<any, any>(
    ({ fit, fluid, className, ...props }, ref) => (
        <div
            {...props}
            ref={ref}
            className={clsx(
                'card-body',
                {
                    'card-body-fit': fit,
                    'card-body-fluid': fluid,
                },
                className
            )}
        />
    )
);

export const CardFooter = forwardRef<any, any>(
    ({ className, ...props }, ref) => (
        <div {...props} ref={ref} className={clsx('card-footer', className)} />
    )
);

export const Card = forwardRef<any, any>(
    ({ fluidHeight, className, ...props }, ref) => (
        <div
            {...props}
            ref={ref}
            className={clsx(
                'card card-custom gutter-b',
                { 'card-height-fluid': fluidHeight },
                className
            )}
        />
    )
);
