import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LocalStorageService from "src/utils/local-storage";
import CreateProfileForm from "../Form/CreateProfileForm";

const ProfilePageHome: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <h1>
        <b>GOLDEN ROADWAYS</b>
      </h1>
      <h2>Profile Details</h2>
      <CreateProfileForm id={LocalStorageService.getAccount().id} />
    </>
  );
};

export default withRouter(ProfilePageHome);
