import * as React from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Row, Col, Card, Avatar, Divider, Statistic, Button } from "antd";
import { useQuery } from "react-query";
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { isMobile } from "react-device-detect";

import { getParty } from "src/modules/Party/Requests";
import { getDriver } from "src/modules/Driver/Requests";
import { getGr } from "src/modules/GR List/Requests";

import { getGatepass } from "src/modules/Gate Pass/Requests";
import { getAllStats } from "src/modules/Profile/Requests";

const CompanyStats: React.FC<{ id: string }> = (props) => {
  const { push } = useHistory();

  const { data: stats } = useQuery(["stats"], () => getAllStats(), {
    refetchOnWindowFocus: false,
    // enabled: false,
    keepPreviousData: true,
    staleTime: 5000,
    // refetchOnMount: false,
  });

  return (
    <>
      <Card>
        <Divider orientation="left"> Company Stats</Divider>
        <Row gutter={16}>
          <Col span={isMobile ? 12 : 6}>
            {/* <Divider orientation='left' > Company Info</Divider> */}

            <Statistic title="Active Party" value={stats?.totalPartyCount} />
            <Button
              style={{ marginTop: 16 }}
              type="primary"
              onClick={() => push("/party")}
            >
              Add Party
            </Button>
          </Col>
          <Col span={isMobile ? 12 : 6}>
            {/* <Divider orientation='left' > Company Info</Divider> */}

            <Statistic
              title="Assigned Person"
              value={stats?.totalDriverCount}
            />
            <Button
              style={{ marginTop: 16 }}
              type="primary"
              onClick={() => push("/driver")}
            >
              Add Driver
            </Button>
          </Col>
          <Col span={isMobile ? 12 : 6}>
            {/* <Divider orientation='left' > Company Info</Divider> */}

            <Statistic title="GR List" value={stats.totalGrCount} />
            <Button
              style={{ marginTop: 16 }}
              type="primary"
              onClick={() => push("/gr")}
            >
              GR List
            </Button>
          </Col>
          <Col span={isMobile ? 12 : 6}>
            <Statistic title="Gate Pass" value={stats?.totalGatepassCount} />
            <Button
              style={{ marginTop: 16 }}
              type="primary"
              onClick={() => push("/gatepass")}
            >
              Create GatePass
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CompanyStats;
