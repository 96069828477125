import { TRoute } from "../../utils/routes";
import GatepassPageHome from "./Page/Home";
import Gatepass from "./Form/GatePass";

const GatepassRouter: TRoute[] = [
  {
    path: "/gatepass",
    name: "gatepass",
    title: "Gatepass",
    component: GatepassPageHome,
  },
  {
    path: "/gatepasspdf/:id",
    name: "gatepasspdf",
    title: "Gatepass Pdf",
    component: Gatepass,
  },
];

export default GatepassRouter;
