import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// import LocalStorageService from 'src/utils/local-storage';
import CookiesService from "src/utils/cookie";
import LocalStorageService from "src/utils/local-storage";
import CompanyCard from "../Component/CompanyCard";
import CompanyStats from "../Component/CompanyStats";
//import CompanyCard from "../Component/CompanyCard";
//import CompanyStats from "../Component/CompanyStats";

// import DashboardSalesWidget from '../Component/SalesWidget';
// import DashboardLatestTransactionsWidget from '../Component/LatestTransactionsWidget';
// import DashboardActionNeededWidget from '../Component/ActionNeeded';

const DashboardPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <h2>Dashboard</h2>
      <CompanyCard id={LocalStorageService?.getAccount()?.id} />
      {LocalStorageService.getRoles() !== "ROLE_MODERATOR" &&
      LocalStorageService.getRoles() !== "ROLE_VIEWER" ? (
        <CompanyStats id={LocalStorageService?.getAccount()?.id} />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(DashboardPageHome);
