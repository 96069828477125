import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import "./core/i18n";
import Routes from "./core/Routes";
import "./assets/sass/style.scss";
import AccountPageLogin from "./modules/Account/Page/Login";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthContext } from "./modules/Account/Context/AuthContext";
// import LocalStorageService from './utils/local-storage';
import CookiesService from "./utils/cookie";
import { Spin, Alert } from "antd";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Mobile from "./modules/Mobile/MobileView";
import LocalStorageService from "./utils/local-storage";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      staleTime: 5000,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const Root: React.FC = () => {
  // return isMobile ? (
  //   <Mobile />
  // ) : (
  //   <Router>
  //     <Route component={Routes} />
  //   </Router>
  // );
  return (
    <Router>
      <Route component={Routes} />
    </Router>
  );
};

const MainApp: React.FC = () => {
  const localToken = CookiesService.getToken();
  // const localToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ2MDY2MzY1LCJleHAiOjE2NDYxNTI3NjV9.qND3gqpuLdmXL_GwE4bXsiIO7qOeumcMObVCVPqSSIQ';//LocalStorageService.getToken();
  const [token, setToken] = React.useState(localToken || "");
  // console.log(token, "token");
  return (
    <React.Suspense
      fallback={
        <>
          <Spin spinning={true}>
            <Alert
              message="The content on the page is loading"
              description="Please wait for just a second..."
              type="info"
            />
          </Spin>
        </>
      }
    >
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ token, setToken }}>
          {token ? <Root /> : <AccountPageLogin />}
          <ReactQueryDevtools />
        </AuthContext.Provider>
      </QueryClientProvider>
    </React.Suspense>
  );
};

ReactDOM.render(<MainApp />, document.getElementById("root") as HTMLElement);
