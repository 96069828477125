import _ from "lodash";
// const path = "http://localhost:8080";
// const path = "https://gapi.techabilititsolutions.com:8081";
const path = "https://api-gr.infinitminds.in:8081";
const routes = {
  /**
   * @property {array} Array of all available routes
   */
  collection: [
    // registration
    {
      method: "post",
      path: path + "/api/auth/signup",
      module: "administration",
      alias: "user.signup",
      isSubDomain: true,
    },
    // login
    {
      method: "post",
      path: path + "/api/auth/signin",
      module: "administration",
      alias: "user.login",
      isSubDomain: true,
    },
    //Get User List
    {
      method: "get",
      path: path + "/api/admin/findalluser",
      module: "administration",
      alias: "user.list",
      isSubDomain: true,
    },
    //Change Password
    {
      method: "put",
      path: path + "/api/admin/userpassword/:id",
      module: "administration",
      alias: "user.update.password",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "v1/administration/dashboard",
      module: "administration",
      alias: "admin.dashboard",
      isSubDomain: true,
    },
    // Insert data in Profile
    {
      method: "post",
      path: path + "/api/user/profile",
      module: "profile",
      alias: "user.profile.create",
      isSubDomain: true,
    },
    // Update data in Profile
    {
      method: "put",
      path: path + "/api/user/profile/:id",
      module: "profile",
      alias: "user.profile.update",
      isSubDomain: true,
    },
    // Read All data from Profile
    {
      method: "get",
      path: path + "/api/user/profile/",
      module: "profile",
      alias: "user.profile.read.all",
      isSubDomain: true,
    },
    // get user profile
    {
      method: "get",
      path: path + "/api/user/profilebyuser/:id",
      module: "profile",
      alias: "user.profile.active",
      isSubDomain: true,
    },
    //Stats
    {
      method: "get",
      path: path + "/api/user/findAllStats",
      module: "profile",
      alias: "user.profile.stats",
      isSubDomain: true,
    },

    // Insert Data in Parties
    {
      method: "post",
      path: path + "/api/user/party",
      module: "party",
      alias: "user.party.create",
      isSubDomain: true,
    },
    //Update data in Parties
    {
      method: "put",
      path: path + "/api/user/party/:id",
      module: "party",
      alias: "user.party.update",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/admin/deleteParty/:id",
      module: "party",
      alias: "user.party.delete",
      isSubDomain: true,
    },
    //Read all data from parties
    {
      method: "get",
      path: path + "/api/user/party",
      module: "party",
      alias: "user.party.list",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/party/:id",
      module: "party",
      alias: "user.party.read",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/mod/partyGst",
      module: "party",
      alias: "user.party.gst",
      isSubDomain: true,
    },

    // Insert value in driver
    {
      method: "post",
      path: path + "/api/user/driver",
      module: "driver",
      alias: "user.driver.create",
      isSubDomain: true,
    },
    // Update value in driver
    {
      method: "put",
      path: path + "/api/user/driver/:id",
      module: "driver",
      alias: "user.driver.update",
      isSubDomain: true,
    },
    // Read data from driver
    {
      method: "get",
      path: path + "/api/user/driver",
      module: "driver",
      alias: "user.driver.list",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/driver/:id",
      module: "driver",
      alias: "user.driver.read",
      isSubDomain: true,
    },

    // Challan
    {
      method: "post",
      path: path + "/api/user/challan",
      module: "challan",
      alias: "user.challan.create",
      isSubDomain: true,
    },

    {
      method: "put",
      path: path + "/api/user/challan/:id",
      module: "challan",
      alias: "user.challan.update",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/admin/deleteChallan/:id",
      module: "challan",
      alias: "user.challan.delete",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/user/challanUnloadingDate/:id",
      module: "challan",
      alias: "user.challan.unloading.update",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/challan",
      module: "challan",
      alias: "user.challan.list",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/challan/:id",
      module: "challan",
      alias: "user.challan.read",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/mod/challanwithGST/:id",
      module: "challan",
      alias: "user.challan.gst",
      isSubDomain: true,
    },

    // gr_list
    {
      method: "post",
      path: path + "/api/user/gr",
      module: "gr",
      alias: "user.gr.create",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/user/gr/:id",
      module: "gr",
      alias: "user.gr.update",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/gr",
      module: "gr",
      alias: "user.gr.list",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/mod/grwithgst",
      module: "gr",
      alias: "user.gr.gst",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/gr/:id",
      module: "gr",
      alias: "user.gr.read",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/grforgatepass",
      module: "gr",
      alias: "user.gr.gatepass",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/admin/deleteGr/:id",
      module: "gr",
      alias: "user.gr.delete",
      isSubDomain: true,
    },

    // gate pass
    {
      method: "post",
      path: path + "/api/user/gatepass",
      module: "gatepass",
      alias: "user.gatepass.create",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/admin/gatepass/:id",
      module: "gatepass",
      alias: "user.gatepass.update",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/user/gatepassStatus/:id",
      module: "gatepass",
      alias: "user.gatepass.status.update",
      isSubDomain: true,
    },
    {
      method: "put",
      path: path + "/api/admin/deleteGatepass/:id",
      module: "gatepass",
      alias: "user.gatepass.delete",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/gatepass",
      module: "gatepass",
      alias: "user.gatepass.list",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/mod/gatepasswithgst",
      module: "gatepass",
      alias: "user.gatepass.gst",
      isSubDomain: true,
    },
    {
      method: "get",
      path: path + "/api/user/gatepass/:id",
      module: "gatepass",
      alias: "user.gatepass.read",
      isSubDomain: true,
    },
  ],

  /**
   * Method used to generate route path from alias.
   *
   * @param {string} alias
   * @param {object} parameters
   */
  route: (alias: string, parameters: any = {}): any => {
    const routeCollection = _.cloneDeep(routes.collection);
    let route = _.find(routeCollection, { alias });
    if (route === undefined) {
      throw new Error(`Can't find route for alias: ${alias}`);
    }

    if (parameters) {
      for (const param in parameters) {
        if (!route.path.includes(param)) {
          throw new Error(
            `Route parameter: ${param} is not allowed in route path: ${route.path}`
          );
        }

        if (
          typeof parameters === "object" &&
          parameters.hasOwnProperty(param)
        ) {
          route.path = route.path.replace(`:${param}`, parameters[param]);
        }
      }
    }

    return route;
  },
};

export default routes;
