import { useEffect } from "react";
import { Redirect } from "react-router";
import LocalStorageService from "../utils/local-storage";
import CookiesService from "../utils/cookie";
// import { queryClient } from '../index';
// import { useAppDispatch } from "../store/hooks";
// import { authorize, setAccount } from "../store/slices/authSlice";

export const Logout = () => {
    // const dispatch = useAppDispatch();

    useEffect(() => {
        CookiesService.removeToken();
        LocalStorageService.removeAccount();
        CookiesService.removeCompany();
        // dispatch(authorize(false));
        // dispatch(setAccount({}));
        // queryClient.removeQueries('authProfile');
        window.location.reload();


    }, []);

    return <Redirect to="/" />;
};
