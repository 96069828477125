// import { Avatar, Tag } from "antd";
import moment from "moment";
import * as React from "react";
// import SVG from "react-inlinesvg";
// import { request } from 'src/utils/services';
import { useMutation, useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
// import { Datatable } from "../../../../components/Datatable";
import ChallanManageModal from "../../Modal/ManageChallan";
import { getChallan } from "../../Requests";
//import { getInstrumentById } from 'src/modules/Instruments/Requests';
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import {
  Button,
  Tooltip,
  Space,
  Input,
  Table,
  Popconfirm,
  message,
} from "antd";
//import type { InputRef } from "antd";
//import type { InputRef } from "antd";
import ChallanViewModal from "../../Modal/ShowChallanDetails";
import {
  EyeOutlined,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import UnloadingChallanModal from "../../Modal/UnloadingChallanDetails";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { queryClient } from "src";
import { request } from "src/utils/services";
import { isMobile } from "react-device-detect";
const ChallanList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<Input>(null);
  const DeleteChallanMutation = useMutation(
    (id: number) =>
      request(
        "user.challan.delete",
        { id: id },
        {
          isDeleted: true,
          deletedAt: moment().format("YYYY-MM-DD hh:mm:ss"),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("challan");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["challan", LocalStorageService.getAccount().id, page, size],
    () => getChallan(),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
      keepPreviousData: true,
      staleTime: 5000,
      // refetchOnMount: false,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  interface DataType {
    challanNo: any;
    id: number;
    // render?:(_:any,record:any)
    //address: string;
  }
  type DataIndex = keyof DataType;
  //const dateFormatList = ["DD/MM/YYYY"];

  const column: ColumnsType<DataType> = isMobile
    ? [
        LocalStorageService.getRoles() !== "ROLE_MODERATOR"
          ? {
              title: "Challan Id",
              dataIndex: "id",
              key: "id",
              width: "100px",
              render: (text: any) => <p>{"GRCH-" + text}</p>,
              defaultSortOrder: "descend",
              sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
              //render: (text: any) => moment(text).format("D MMM y"),
            }
          : {},
        {
          title: " Date",

          dataIndex: "challanDate",
          key: "challanDate",
          render: (text: any) => moment(text).format("DD MMM YYYY"),
          width: "100px",
          // defaultSortOrder: "descend",
          // render: (text: any) => (
          //   //getInstrumentById(id);
          //   <p>{"SKY/ENQ/" + text}</p>
          // ),
        },
        {
          title: "Ch. No.",
          dataIndex: "challanNo",
          key: "challanNo",
          ...getColumnSearchProps("challanNo"),
          width: "120px",

          //     render: (id: any) => {
          //         getInstrumentById(id);

          //     }
        },
        {
          title: "Lorry",
          dataIndex: "lorryNo",
          key: "lorryNo",
          width: "70px",
        },
        {
          title: "From",
          dataIndex: "pickupLocation",
          key: "pickupLocation",
          width: "100px",
        },
        {
          title: "To",
          dataIndex: "destination",
          key: "destination",
          width: "100px",
        },

        {
          title: "Unloading",
          dataIndex: "unLoadingDate",
          key: "unLoadingDate",
          width: "100px",

          render: (_: any, record: any) =>
            record.unLoadingDate === null ? (
              <Space size={10}>
                <UnloadingChallanModal
                  id={record.id}
                  title="Add Unloading Date"
                />
                {/* <ChallanViewModal id={id} />
        <ChallanManageModal id={id} /> */}
              </Space>
            ) : (
              <>
                {moment(record.unLoadingDate).format("DD-MMM-YYYY")}
                {LocalStorageService.getRoles() === "ROLE_ADMIN" && (
                  <UnloadingChallanModal
                    id={record.id}
                    title="Edit Unloading Date"
                    type="Text"
                  />
                )}
              </>
            ),
        },

        {
          title: "Action",
          dataIndex: "id",
          key: "id",
          fixed: "right",
          width: "25vw",

          render: (id: any) => (
            <Space size={10}>
              <ChallanViewModal id={id} />
              <ChallanManageModal id={id} />
              {LocalStorageService.getRoles() === "ROLE_ADMIN" && (
                <Popconfirm
                  title="Delete Challan?"
                  onConfirm={() => {
                    DeleteChallanMutation.mutateAsync(id)
                      .then(({ payload }) => {
                        console.log(payload);

                        message.success(payload.message);
                      })
                      .catch((e: any) => {
                        message.error(e.message);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Space>
          ),
        },
      ]
    : [
        LocalStorageService.getRoles() !== "ROLE_MODERATOR"
          ? {
              title: "Challan Id",
              dataIndex: "id",
              key: "id",
              render: (text: any) => <p>{"GRCH-" + text}</p>,
              defaultSortOrder: "descend",
              sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
              //render: (text: any) => moment(text).format("D MMM y"),
            }
          : {},
        {
          title: "Challan Date",

          dataIndex: "challanDate",
          key: "challanDate",
          render: (text: any) => moment(text).format("DD MMM YYYY"),
          // render: (text: any) => (
          //   //getInstrumentById(id);
          //   <p>{"SKY/ENQ/" + text}</p>
          // ),
        },
        {
          title: "Challan No.",
          dataIndex: "challanNo",
          key: "challanNo",
          ...getColumnSearchProps("challanNo"),
          //     render: (id: any) => {
          //         getInstrumentById(id);

          //     }
        },
        {
          title: "Lorry No.",
          dataIndex: "lorryNo",
          key: "lorryNo",
        },
        {
          title: "From",
          dataIndex: "pickupLocation",
          key: "pickupLocation",
        },
        {
          title: "To",
          dataIndex: "destination",
          key: "destination",
        },

        {
          title: "Unloading Date",
          dataIndex: "unLoadingDate",
          key: "unLoadingDate",
          render: (_: any, record: any) =>
            record.unLoadingDate === null ? (
              <Space size={10}>
                <UnloadingChallanModal
                  id={record.id}
                  title="Add Unloading Date"
                />
                {/* <ChallanViewModal id={id} />
        <ChallanManageModal id={id} /> */}
              </Space>
            ) : (
              <>
                <h3> {moment(record.unLoadingDate).format("DD-MMM-YYYY")}</h3>
                {LocalStorageService.getRoles() === "ROLE_ADMIN" && (
                  <UnloadingChallanModal
                    id={record.id}
                    title="Edit Unloading Date"
                    type="Text"
                  />
                )}
              </>
            ),
        },

        {
          title: "Action",
          dataIndex: "id",
          key: "id",
          render: (id: any) => (
            <Space size={10}>
              <ChallanViewModal id={id} />
              <ChallanManageModal id={id} />
              {LocalStorageService.getRoles() === "ROLE_ADMIN" && (
                <Popconfirm
                  title="Delete Challan?"
                  onConfirm={() => {
                    DeleteChallanMutation.mutateAsync(id)
                      .then(({ payload }) => {
                        console.log(payload);

                        message.success(payload.message);
                      })
                      .catch((e: any) => {
                        message.error(e.message);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Space>
          ),
        },
      ];
  //const searchInput = React.useRef<InputRef>(null);
  // console.log(CookiesService.getToken(), "token")

  return isMobile ? (
    <Table
      columns={column}
      size={"small"}
      dataSource={response?.Challan}
      scroll={{ x: "100vw", y: "55vh" }}
    />
  ) : (
    <Table
      columns={column}
      size={"small"}
      dataSource={response?.Challan}
      // scroll={{ x: "100vw", y: "55vh" }}
    />
  );
};

export default ChallanList;
