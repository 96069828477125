import { request } from "src/utils/services";

export const getGatepass = async () =>
  // //id: number,
  // page: number = 1,
  // size: number = 10
  // // { page = 1, size = 10 }: { page?: number; size?: number },
  // // sort?: { field: string; dir: string },
  // // fromdate: string = "",
  // // todate: string = "",
  // // country: string = "",
  // // paymentlist: string = ""
  {
    // let query: {
    //   page: number;
    //   size: number;
    //   // type: string;
    //   // sortField?: string;
    //   // sortDir?: string;
    //   // fromdate?: string;
    //   // todate?: string;
    //   // country?: string;
    //   // paymentlist?: string;
    // } = {
    //   page,
    //   size,

    //   //type,
    //   // fromdate,
    //   // todate,
    //   // country,
    //   // paymentlist,
    // };

    // if (sort) {
    //   query.sortField = sort.field;
    //   query.sortDir = sort.dir;
    // }

    const { payload } = await request(
      "user.gatepass.list"
      //{ id },
      // {
      //   query,
      // }
    );

    return payload;
  };

export const getGatepassById = async (id: number) => {
  const { payload } = await request("user.gatepass.read", { id });
  return payload;
};
export const getGatepassGST = async () => {
  const { payload } = await request("user.gatepass.gst");
  return payload;
};

export const updateGatepassStatusById = async (id: number) => {
  const { payload } = await request("user.gatepass.status.update", { id });
  return payload;
};
// export const approveTransactionById = async (id: string) => {
//   return request("admin.billing.withdraw.approve", { id });
// };
// export const getPaymentList = async () => {
//   return request("billing.paymethod", {});
// };
