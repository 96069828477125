import * as React from "react";
import { Form, Input, Button, message, Switch, Select } from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageUsersModalContext } from "../Modal/ManageUsers";
//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
//import { getUsersById } from "../Requests";

// import FormFile from '../../../components/Form/File';

const ManageUsersForm: React.FC<{ id: number }> = (props) => {
  const modalContext = React.useContext(ManageUsersModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [parameters, setParameters] = React.useState([]);

  // console.log(users, "data");
  const ManageUsersMutation = useMutation(
    (values: any) => request("user.update.password", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    }
  );

  const onFinish = async (values: any) => {
    values.user_id = LocalStorageService.getAccount().id;
    values.company_id = CookiesService.getCompany();

    setFormLoading(true);
    if (values.password === values.repeatPassword) {
      ManageUsersMutation.mutateAsync(values)
        .then(() => {
          setFormLoading(false);
          message.success("The users has been successfully Managed.");
          modalContext.setIsModalVisible(false);
        })
        .catch((e: any) => {
          setFormLoading(false);
          message.error(e);
        });
    } else {
      message.error("Password do not Match");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // initialValues={users?.Users}
    >
      <Form.Item
        label="New Password"
        name="password"
        tooltip={{
          title: "New Password",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Repeat Password"
        name="repeatPassword"
        tooltip={{
          title: "Repeat Password",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input maxLength={10} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Users
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageUsersForm;
