import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Table,
  Tag,
  Button,
  Tooltip,
} from "antd";
// import { request } from 'src/utils/services';
import { useQuery } from "react-query";
import { getChallanById, getChallanGSTById } from "../Requests";
import { ColumnsType } from "antd/lib/table/interface";

import moment from "moment";
import LocalStorageService from "src/utils/local-storage";

import { isMobile } from "react-device-detect";

const ShowChallanDetailsForm: React.FC<{ id: number }> = (props) => {
  //const [parameters, setParameters] = React.useState([]);
  const { isLoading, error, data } = useQuery(
    ["ChallanRequest", props.id],
    async () =>
      LocalStorageService.getRoles() === "ROLE_MODERATOR"
        ? getChallanGSTById(props.id)
        : getChallanById(props.id)
  );

  var a = 1;
  const GrList: ColumnsType<any> = isMobile
    ? [
        {
          title: "S No.",
          dataIndex: "challanSno",
          key: "challanSno",
          fixed: "left",
          width: "10vw",
          render: (text: any) =>
            LocalStorageService.getRoles() !== "ROLE_MODERATOR" ? text : a++,
        },
        {
          title: "GR No.",
          dataIndex: "grNumber",
          key: "grNumber",

          width: "20vw",
          fixed: "left",
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          render: (text: any) => moment(text).format("D MMM y"),
          width: "100px",
        },

        {
          title: "No. Of Pkgs",
          dataIndex: "quantity",
          key: "quantity",
          width: "80px",
        },

        {
          title: "Contains",
          dataIndex: "content",
          key: "content",
          width: "100px",
        },
        {
          title: "Paid Amount",
          dataIndex: "paidAmount",
          key: "paidAmount",
          width: "100px",
        },
        {
          title: "To Pay Amount",
          dataIndex: "toPayAmount",
          key: "toPayAmount",
          width: "100px",
        },

        {
          title: "Private Mark",
          dataIndex: "privateMark",
          key: "privateMark",
          width: "100px",
        },
        {
          title: "Status",
          dataIndex: "grStatus",
          key: "grStatus",
          width: "22vw",
          fixed: "right",
          render: (text: any) =>
            text === "Intransit" ? (
              <Tag color="#f50">{text}</Tag>
            ) : text === "At Warehouse" ? (
              <Tag color="#F502e9">
                {text}
                {/* <h1>{text}</h1> */}
              </Tag>
            ) : text === "Partial Dispatch" ? (
              <Tag color="#e6b800">{text}</Tag>
            ) : (
              <Tag color="#004d00">
                {text}
                {/* <h1>{text}</h1> */}
              </Tag>
            ),
        },
      ]
    : [
        {
          title: "S No.",
          dataIndex: "challanSno",
          key: "challanSno",
          render: (text: any) =>
            LocalStorageService.getRoles() !== "ROLE_MODERATOR" ? text : a++,
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "data",
          render: (text: any) => moment(text).format("DD MMM YYYY"),
        },
        // {
        //   title: "Challan No.",
        //   dataIndex: "challanId",
        //   key: "challanId",
        //   // render: (text: any) => (
        //   //   //getInstrumentById(id);
        //   //   <p>{"SKY/ENQ/" + text}</p>
        //   // ),
        // },
        {
          title: "GR No.",
          dataIndex: "grNumber",
          key: "grNumber",
          //     render: (id: any) => {
          //         getInstrumentById(id);

          //     }
        },
        {
          title: "No. Of Pkgs",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "Weight",
          dataIndex: "weight",
          key: "weight",
        },
        {
          title: "Contains",
          dataIndex: "content",
          key: "content",
        },
        {
          title: "Paid Amount",
          dataIndex: "paidAmount",
          key: "paidAmount",
        },
        {
          title: "To Pay Amount",
          dataIndex: "toPayAmount",
          key: "toPayAmount",
        },
        {
          title: "Private Mark",
          dataIndex: "privateMark",
          key: "privateMark",
        },
        {
          title: "Status",
          dataIndex: "grStatus",
          key: "grStatus",
          render: (text: any) =>
            text === "Intransit" ? (
              <Tag color="#f50">{text}</Tag>
            ) : text === "At Warehouse" ? (
              <Tag color="#F502e9">
                {text}
                {/* <h1>{text}</h1> */}
              </Tag>
            ) : text === "Partial Dispatch" ? (
              <Tag color="#87d041">{text}</Tag>
            ) : (
              <Tag color="#004d00">
                {text}
                {/* <h1>{text}</h1> */}
              </Tag>
            ),
        },
      ];
  return (
    //<h1> {Instrument?.payload?.status}</h1>
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Challan No" value={data?.challan?.challanNo} />
        </Col>
        <Col span={12}>
          <Statistic
            title="Challan Date"
            value={moment(data?.challan?.challanDate).format("DD MMM YYYY")}
          />
        </Col>
      </Row>
      <Divider orientation="left">Challan Info</Divider>

      <Descriptions bordered column={isMobile ? 1 : 3} size="small">
        <Descriptions.Item label="Lorry No">
          {data?.challan?.lorryNo}
        </Descriptions.Item>

        <Descriptions.Item label="From">
          {data?.challan?.pickupLocation}
        </Descriptions.Item>

        <Descriptions.Item label="To">
          {data?.challan?.destination}
        </Descriptions.Item>
      </Descriptions>

      <Col span={24}>
        <Divider orientation="left">GR List</Divider>
        {isMobile ? (
          <Table
            columns={GrList}
            dataSource={data.gr[0]}
            size={"small"}
            pagination={false}
            scroll={{ x: "100vw" }}
          />
        ) : (
          <Table
            columns={GrList}
            dataSource={data.gr[0]}
            size={"small"}
            pagination={false}
          />
        )}
        {/* <div>
          <Steps progressDot current={currentStatus}>
            <Step title="New" description="Challan Request Generated." />
            <Step
              title="In Progress"
              description="Request Accepted.Calibaration under Process"
            />
            <Step title="In Review" description="Challan under Review." />
            <Step
              title="Report Generation"
              description="Challan Done. Report under Generation"
            />
            <Step
              title="Completed"
              description="You can now download report."
            />
          </Steps>
        </div> */}
      </Col>

      <div>
        <Descriptions
          size="small"
          //title="Responsive Descriptions"
          bordered
          column={isMobile ? 1 : 4}
        >
          <Descriptions.Item label="Total Pkg.">
            {data?.challan?.totalPackage}
          </Descriptions.Item>
          <Descriptions.Item label="Total Wt.">
            {data?.challan?.totalWeight}
          </Descriptions.Item>
          <Descriptions.Item label="T.Paid Amt">
            ₹ {data?.challan?.totalPaidAmount}
          </Descriptions.Item>
          <Descriptions.Item label="T.ToPay Amt">
            ₹ {data?.challan?.totalToPayAmount}
          </Descriptions.Item>
        </Descriptions>
      </div>

      {/* <Datatable
        columns={columns}
        noHeader={true}
        data={parameters?.InstrumentParameters}
        sortIcon={
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG
              src={'/static/media/svg/icons/Navigation/Arrow-up.svg'}
            />
          </span>
        }
      /> */}

      <Divider orientation="left"></Divider>
      <div>
        <span>
          {"GRCH-" + data?.challan?.id + "/ " + data.challan.createdAt}
        </span>
      </div>
    </>
  );
};

export default ShowChallanDetailsForm;
