import styled from 'styled-components';

export const LoginContainer=styled.div`
display: flex;
flex-direction: column;
height: 100vh;
overflow: auto;
background:#f0f2f;
@media (min-width:768px) {
      background-image: url('/static/svg/bg.svg');
      background-repeat: no-repeat;
      background-position: center 110px;
      background-size: 100%;
  }
  .icon {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;
  
    &:hover {
      color: #1890ff;
    }
  
`;

export const LoginContent=styled.div`
flex: 1;
padding: 32px 0;
@media (min-width:768px) {
    padding: 32px 0 24px;
}`;