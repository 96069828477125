import * as React from "react";

import { Row, Col, Card, Avatar, Divider, message } from "antd";
import { useQuery } from "react-query";

import logo from "../../../assets/img/logo.png";

import {
  BankOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  AuditOutlined,
  IdcardOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

import { getProfileByUser } from "src/modules/Profile/Requests";
import { isMobile } from "react-device-detect";

const CompanyCard: React.FC<{ id: string }> = (props) => {
  const {
    isLoading,
    data: profileData,
    error,
    isError,
  } = useQuery(["company", props.id], async () => getProfileByUser(props.id), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  return (
    <>
      <Card>
        <Row gutter={16}>
          <Col span={isMobile ? 24 : 12}>
            <img width="75%" src={logo} />
          </Col>
          {!isMobile && (
            <Col span={isMobile ? 24 : 12}>
              <Divider orientation="left"> Company Info</Divider>

              <Card bordered={true}>
                <h3>
                  <BankOutlined /> {" " + profileData?.Profile?.companyName}
                </h3>
                <p>
                  <EnvironmentOutlined />
                  {" " +
                    profileData?.Profile?.address +
                    "-" +
                    profileData?.Profile?.pinCode}
                </p>
                <p>
                  <PhoneOutlined />
                  {" +91-" + profileData?.Profile?.mobile + ", "}{" "}
                  <MailOutlined />
                  {" " + profileData?.Profile?.email}
                </p>
                <p>
                  <AuditOutlined />
                  {" " + profileData?.Profile?.gstNumber} <IdcardOutlined />
                  {" " + profileData?.Profile?.panCard}
                </p>
                <p>
                  <GlobalOutlined />
                  {" " + profileData?.Profile?.website}{" "}
                </p>
              </Card>
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
};

export default CompanyCard;
