import { Avatar, message, Popconfirm, Tag } from "antd";
import moment from "moment";
import * as React from "react";
import SVG from "react-inlinesvg";
// import { request } from 'src/utils/services';
import { useMutation, useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import { Datatable } from "../../../../components/Datatable";
import PartyManageModal from "../../Modal/ManageParty";
import { getParty, getPartyById, getPartyGST } from "../../Requests";
//import { getInstrumentById } from 'src/modules/Instruments/Requests';
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { Button, Tooltip, Space, Input, Table } from "antd";
import PartyViewModal from "../../Modal/ShowPartyDetails";
import {
  EyeOutlined,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { request } from "src/utils/services";
import { queryClient } from "src";
import { isMobile } from "react-device-detect";

interface DataType {
  //challanNo: any;
  id: number;
  name: any;
  privateMark: any;
  gstNumber: any;
  // render?:(_:any,record:any)
  //address: string;
}
type DataIndex = keyof DataType;
const PartyList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<Input>(null);
  const DeletePartyMutation = useMutation(
    (id: number) =>
      request(
        "user.party.delete",
        { id: id },
        {
          isDeleted: true,
          deletedAt: moment().format("YYYY-MM-DD hh:mm:ss"),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("party");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["party", LocalStorageService.getAccount().id, page, size],
    () =>
      LocalStorageService.getRoles() === "ROLE_MODERATOR"
        ? getPartyGST()
        : getParty(),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
      keepPreviousData: true,
      staleTime: 5000,
      // refetchOnMount: false,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const column: ColumnsType<DataType> = isMobile
    ? [
        {
          title: "Party Name.",
          dataIndex: "name",
          key: "name",
          fixed: "left",
          ...getColumnSearchProps("name"),
          width: "25vw",
        },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
          width: "150px",

          //     render: (id: any) => {
          //         getInstrumentById(id);

          //     }
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
          ellipsis: {
            showTitle: false,
          },
          render: (address) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
          ),
        },

        {
          title: "Private Mark",
          dataIndex: "privateMark",
          key: "privateMark",
          width: "100px",

          ...getColumnSearchProps("privateMark"),
          ellipsis: {
            showTitle: false,
          },
        },
        {
          title: "GST Number",
          dataIndex: "gstNumber",
          key: "gstNumber",
          ...getColumnSearchProps("gstNumber"),
          width: "150px",
          ellipsis: {
            showTitle: false,
          },
          render: (address) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
          ),
        },

        {
          title: "Action",
          dataIndex: "id",
          key: "id",
          fixed: "right",
          width: "20vw",

          render: (id: any) => (
            <Space size={10}>
              {/* <PartyViewModal id={id} /> */}
              <PartyManageModal id={id} />
              {LocalStorageService.getRoles() === "ROLE_ADMIN" && (
                <Popconfirm
                  title="Delete Party?"
                  onConfirm={() => {
                    DeletePartyMutation.mutateAsync(id)
                      .then(({ payload }) => {
                        console.log(payload);

                        message.success(payload.message);
                      })
                      .catch((e: any) => {
                        message.error(e.message);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Space>
          ),
        },
      ]
    : [
        {
          title: "Date",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (text: any) => moment(text).format("D MMM y"),
        },
        {
          title: "Party Name.",
          dataIndex: "name",
          key: "name",
          ...getColumnSearchProps("name"),
        },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
          //     render: (id: any) => {
          //         getInstrumentById(id);

          //     }
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          render: (text: any) => (text !== "" ? <p>{text}</p> : "Null"),
        },
        {
          title: "Private Mark",
          dataIndex: "privateMark",
          key: "privateMark",
          ...getColumnSearchProps("privateMark"),
        },
        {
          title: "GST Number",
          dataIndex: "gstNumber",
          key: "gstNumber",
          ...getColumnSearchProps("gstNumber"),
        },

        {
          title: "Action",
          dataIndex: "id",
          key: "id",
          render: (id: any) => (
            <Space size={10}>
              {/* <PartyViewModal id={id} /> */}
              <PartyManageModal id={id} />
              {LocalStorageService.getRoles() === "ROLE_ADMIN" && (
                <Popconfirm
                  title="Delete Party?"
                  onConfirm={() => {
                    DeletePartyMutation.mutateAsync(id)
                      .then(({ payload }) => {
                        console.log(payload);

                        message.success(payload.message);
                      })
                      .catch((e: any) => {
                        message.error(e.message);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Space>
          ),
        },
      ];

  return isMobile ? (
    <Table
      size="small"
      columns={column}
      dataSource={response?.Party}
      scroll={{ x: "100vw", y: "55vh" }}
    />
  ) : (
    <Table
      size="small"
      columns={column}
      dataSource={response?.Party}
      //scroll={{ x: "100vw", y: "55vh" }}
    />
  );
};

export default PartyList;
