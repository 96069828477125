import { TRoute } from "../../utils/routes";
import ChallanPageHome from "./Page/Home";
import Challan from "./Form/Challan";

const ChallanRouter: TRoute[] = [
  {
    path: "/challan",
    name: "challan",
    title: "Challan",
    component: ChallanPageHome,
  },
  {
    path: "/challanpdf/:id",
    name: "challanpdf",
    title: "Challanpdf",
    component: Challan,
  },
];

export default ChallanRouter;
