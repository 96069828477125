import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Button } from "antd";
import ShowChallanDetailsForm from "./ShowChallanDetailsForm";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import { PrintableBodyWrapper } from "src/modules/Gate Pass/Form/GatePass.styled";
const Challan: React.FC<RouteComponentProps> = (props) => {
  const location = useLocation();
  let cid: string = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const { push } = useHistory();
  const PrintDocument = () => {
    // console.log("Print");
    // print('myDiv', 'html');
    window.print();
  };
  return (
    <>
      <PrintableBodyWrapper>
        <Button
          className="no-print"
          onClick={() => push("/challan")}
          style={{ margin: "2rem" }}
        >
          Back
        </Button>
        <Button className="no-print" type="primary" onClick={PrintDocument}>
          Print Now
        </Button>

        <div style={{ margin: "0 2rem 0 2rem", textAlign: "center" }}>
          <img src={logo} alt="logo" style={{ width: "200px" }} />
          <ShowChallanDetailsForm id={+cid} />
        </div>
      </PrintableBodyWrapper>
    </>
  );
};
export default withRouter(Challan);
