import * as React from "react";
import { Form, Input, Button, message, Col, Row, Space } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  MobileOutlined,
  MinusCircleOutlined,
  MailOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  BankOutlined,
  GlobalOutlined,
  AuditOutlined,
  IdcardOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { getProfileByUser, createProfile, updateProfile } from "../Requests";
// import LocalStorageService from 'src/utils/local-storage';
import CookiesService from "src/utils/cookie";
const CreateProfileForm: React.FC<{ id: string }> = (props) => {
  const [formLoading, setFormLoading] = React.useState(false);
  // get profile details
  const {
    //isLoading,
    data: profileData,
    //error,
    //isError,
  } = useQuery(["profile", props.id], async () => getProfileByUser(props.id), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  //profileData.profile.concernedPerson = profileData.concernedPerson;
  CookiesService.storeCompany(profileData.Profile.id);
  // console.log(LocalStorageService.getprofile(), "==>");

  // save or,update profile Mutation
  const profileMutation = useMutation(
    (values: any) =>
      profileData
        ? updateProfile(profileData.Profile.id, values)
        : createProfile(props.id, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("profile");
      },
    }
  );
  const onFinish = async (values: any) => {
    setFormLoading(true);
    profileMutation
      .mutateAsync(values)
      .then((payload: any) => {
        setFormLoading(false);
        message.success(payload.message);
        // modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  //   if (isLoading) return <div>Loading...</div>;
  //   if (isError || error) return <h2>Something went Wrong...</h2>;
  return (
    <Form
      layout="vertical"
      initialValues={profileData.Profile}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            label="Company Name"
            name="companyName"
            tooltip={{
              title: "Profile Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please input your Profile name!" },
            ]}
          >
            <Input
              addonBefore={<BankOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="Phone No"
            name="mobile"
            tooltip={{
              title: "Official Phone no",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true, message: "Please input your phone no!" }]}
          >
            <Input
              // addonBefore="+91-"
              addonAfter={<MobileOutlined />}
              // style={{ width: 400, }}
              maxLength={10}
            />
          </Form.Item>
          {/* <Input.Group  > */}

          {/* <Input
                                className="site-input-split"
                                style={{
                                    width: 80,
                                    // borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }}
                                prefix={<MobileOutlined className="site-form-item-icon" />}
                                placeholder="+91"
                                disabled

                            /> */}
        </Col>
        <Col flex={1}>
          <Form.Item
            label="Alt. Phone No"
            name="altMobile"
            tooltip={{
              title: "alt Phone no",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please input your Alt mobile no!" },
            ]}
          >
            <Input
              // addonBefore="+91-"
              addonAfter={<MobileOutlined />}
              // style={{ width: 400, }}
              maxLength={10}
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="Email Id"
            name="email"
            tooltip={{
              title: "Official Email Id",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              addonBefore={<MailOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            label="Website"
            name="website"
            tooltip={{
              title: "Website e.g www.example.com",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item
            label="Pan Card"
            name="panCard"
            tooltip={{
              title: "Enter 10 Digit Pan card no.",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<IdcardOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item
            label="GST No."
            name="gstNumber"
            tooltip={{
              title: "GSTIN No",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={2}>
          <Form.Item
            label="Address"
            name="address"
            tooltip={{
              title: "Office Address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={1} maxLength={500} showCount />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item
            label="Pincode"
            name="pinCode"
            tooltip={{
              title: "Eight digit pincode",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Input
              addonBefore={
                <EnvironmentOutlined className="site-form-item-icon" />
              }
              maxLength={8}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <h3>Personnel List</h3>
      <Form.List name="concernedPerson">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              <Row
                gutter={16}
                key={field.key}
                justify="space-between"
                align="top"
              >
               
                <Col flex={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        //  label="Personnel Name"
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Personnel Name Required",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Personnel Name"
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item
                    {...field}
                    //label="Mobile"
                    name={[field.name, "designation"]}
                    fieldKey={[field.fieldKey, "designation"]}
                    rules={[
                      { required: true, message: "Designation required" },
                    ]}
                  >
                    <Input
                      addonBefore={
                        <HighlightOutlined className="site-form-item-icon" />
                      }
                      placeholder="Enter Designation"
                    />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                 

                  <Form.Item
                    {...field}
                    //label="Mobile"
                    name={[field.name, "phone_no"]}
                    fieldKey={[field.fieldKey, "phone_no"]}
                    rules={[{ required: true, message: "Phone No required" }]}
                  >
                    <Input
                      addonBefore="+91-"
                      addonAfter={
                        <MobileOutlined className="site-form-item-icon" />
                      }
                      maxLength={10}
                      placeholder="Enter Phone No."
                    />
                  </Form.Item>
                  
                </Col>
                <Col flex={1}>
                  <Form.Item
                    {...field}
                    //label="Alt. Mobile"
                    name={[field.name, "alt_phone_no"]}
                    fieldKey={[field.fieldKey, "alt_phone_no"]}
                    // rules={[{ required: true, message: 'Alternate Phone no Required' }]}
                  >
                    <Input
                      addonBefore="+91-"
                      addonAfter={
                        <MobileOutlined className="site-form-item-icon" />
                      }
                      maxLength={10}
                      placeholder="Alt. Mobile No."
                    />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item
                    {...field}
                    //  label="Email"
                    name={[field.name, "email"]}
                    fieldKey={[field.fieldKey, "email"]}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={
                        <MailOutlined className="site-form-item-icon" />
                      }
                      placeholder="Email ID"
                    />
                  </Form.Item>
                </Col>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              
              </Row>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Personnel
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProfileForm;
