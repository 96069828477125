import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Descriptions,
  Upload,
  Divider,
  Card,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  UploadOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { CreateChallanModalContext } from "../Modal/CreateChallan";
//import CreatePartyModal from "../Party/Modal/CreateParty";

//import { getActiveInstrumentByCompany, getActiveParameterByInstruments } from '../../Instruments/Requests';
//import { getCompanyByUser } from '../../Company/Requests';
import { useQuery } from "react-query";
//import { useState } from 'react';
import * as XLSX from "xlsx";

import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import {
  // InfoCircleOutlined,
  UserOutlined,
  MobileOutlined,
  MinusCircleOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getDriver } from "src/modules/Driver/Requests";
import { getParty } from "src/modules/Party/Requests";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import CreateDriverModal from "src/modules/Driver/Modal/CreateDriver";
import moment from "moment";
import { isMobile } from "react-device-detect";

const CreateChallanForm: React.FC<{ id?: any }> = (props) => {
  const modalContext = React.useContext(CreateChallanModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [sno, setSno] = React.useState(1);
  const [pkg, setPkgs] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [paidAmt, setPaidAmt] = React.useState(0);
  const [toPayAmt, setToPayAmt] = React.useState(0);
  const createChallanMutation = useMutation(
    (values: any) => request("user.challan.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("challan");
        queryClient.invalidateQueries("gr");
        queryClient.invalidateQueries("grforgatepass");
      },
    }
  );

  const {
    // isLoading,
    data: party,
    // error,
    // isError,
  } = useQuery(["party"], async () => getParty(), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  // party.gr = party.gr;
  const [form] = Form.useForm();
  // const resetForm = () => {};
  // const [items, setItems] = React.useState({});
  // var exceldata: any;
  // const readExcel = (file: any) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);

  //     fileReader.onload = (e: any) => {
  //       const bufferArray = e.target.result;

  //       const wb = XLSX.read(bufferArray, { type: "buffer" });

  //       const wsname = wb.SheetNames[0];

  //       const ws = wb.Sheets[wsname];

  //       const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(data);

  //       party.gr = data;

  //       //party.challanNo = "123";
  //       setItems(data);
  //       console.log(data, "exl");
  //       console.log(items, "items");

  //       // setItems([]);
  //       // console.log(data, "exl");
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  //   promise.then((d: any) => {
  //     setItems(d);
  //   });
  // };

  const onFinish = async (values: any) => {
    values.user_id = LocalStorageService.getAccount().id;
    values.company_id = CookiesService.getCompany();
    values.challanDate = moment(values.challanDate).format("YYYY-MM-DD");
    for (let i = 0; i < values?.gr.length; i++) {
      values.gr[i].date = moment(values?.gr[i]?.date).format("YYYY-MM-DD");
    }
    values.totalPackage = pkg;
    values.totalWeight = weight;
    values.totalPaidAmount = paidAmt;
    values.totalToPayAmount = toPayAmt;

    setFormLoading(true);

    createChallanMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The challan has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
        setPkgs(0);
        setToPayAmt(0);
        setPaidAmt(0);
        setWeight(0);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleTotal = (_: any, values: any) => {
    // console.log(values, "===>");
    // const rowsCopy = [...values.gr]
    let qty: number = 0; //pkg;
    let wt: number = 0; //weight;
    let paidAmnt: number = 0; //paidAmt;
    let toPayAmt: number = 0; //toPayAmt;
    const grList = values.gr;

    grList?.forEach((fieldGroup: any, index: number) => {
      if (
        fieldGroup &&
        (fieldGroup.quantity ||
          fieldGroup.weight ||
          fieldGroup.paidAmount ||
          fieldGroup.toPayAmount)
      ) {
        qty = fieldGroup.quantity ? qty + +fieldGroup.quantity : pkg;
        // console.log('quantity', qty);

        wt = fieldGroup.weight ? wt + +fieldGroup.weight : weight;
        paidAmnt = fieldGroup.paidAmount
          ? paidAmnt + +fieldGroup.paidAmount
          : paidAmt;
        toPayAmt = fieldGroup.toPayAmount
          ? toPayAmt + +fieldGroup.toPayAmount
          : toPayAmt;
        // console.log(qty, wt, paidAmnt, toPayAmt, "iqty,iweight,paidAmount,topayAmount");
      }
    });
    setPkgs(qty);
    setPaidAmt(paidAmnt);
    setWeight(wt);
    setToPayAmt(toPayAmt);
    // console.log(qty, wt, paidAmnt, toPayAmt, "qty,weight,paidAmount,topayAmount");
  };

  return (
    <Form
      form={form}
      layout="vertical"
      //initialValues={profileData.Profile}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onValuesChange={handleTotal}
      scrollToFirstError
      // style={{ height: "55vh" }}
    >
      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            label="Challan Date"
            name="challanDate"
            tooltip={{
              title: "Challan Date ",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[
            //   { required: true, message: "Please input your Profile name!" },
            // ]}
          >
            <DatePicker format={"DD-MM-YY"} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="Challan No"
            name="challanNo"
            tooltip={{
              title: "Challan No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   type: "number",
              //   message: "The input is not valid E-mail!",
              // },
              { required: true, message: "Please input challan No" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col flex={1}>
          <Form.Item
            label="Lorry Number"
            name="lorryNo"
            tooltip={{
              title: "lorryNo",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   type: "number",
              //   message: "The input is not valid E-mail!",
              // },
              {
                required: true,
                message: "Please input Lorry Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2}>
          <Form.Item
            label="From"
            name="pickupLocation"
            tooltip={{
              title: "Pick-up Location",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
            // addonBefore={<GlobalOutlined className="site-form-item-icon" />}
            // maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item
            label="To"
            name="destination"
            tooltip={{
              title: "Destination",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
            // addonBefore={<IdcardOutlined className="site-form-item-icon" />}
            // maxLength={20}
            />
          </Form.Item>
        </Col>
        {/* <Col flex={2}>
          <Form.Item
            label="Upload Excel File"
            // name="destination"
            tooltip={{
              title: "Upload GR Sheet",
              icon: <InfoCircleOutlined />,
            }}
          // rules={[{ required: true }]}
          >
            <input
              type="file"
              onChange={(e: any) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            /> */}
        {/* <Upload
              onChange={(e: any) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload> */}
        {/* </Form.Item> */}
        {/* <Button
            size="large"
            //loading={formLoading}
            type="primary"
            //htmlType="submit"
            shape="round"
            onClick={() => {
              React.useEffect(() => {
                form.setFieldsValue(party);
              }, [form, party]);
            }}
          >
            Submit
          </Button> */}
        {/* <input
            type="file"
            onChange={(e: any) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          /> */}
        {/* </Col> */}
      </Row>

      <h3>GR List</h3>
      {!isMobile && (
        <table>
          <tr>
            <th style={{ width: "6%" }}>Sno</th>
            <th style={{ width: "12%" }}>Date</th>
            <th style={{ width: "15%" }}>GR Number</th>
            <th style={{ width: "15%" }}>Content</th>
            <th style={{ width: "8%" }}>No.of Pkg</th>
            <th style={{ width: "12%" }}>Weight</th>
            <th style={{ width: "11%" }}>Paid Amt</th>
            <th style={{ width: "9%" }}> To Pay Amount</th>
            <th style={{ width: "8%" }}>Pvt Mark</th>
          </tr>
        </table>
      )}
      <Form.List name="gr">
        {(fields, { add, remove }) => (
          <>
            {fields.map(
              (field: any, index: number) => (
                setSno(index + 1),
                (
                  <Row
                    //gutter={16}
                    key={field.key}
                    justify="space-between"
                    align="top"
                  >
                    <Col style={{ width: !isMobile ? "6%" : "18%" }}>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area ||
                          prevValues.sights !== curValues.sights
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            name={[field.name, "challanSno"]}
                          >
                            <Input placeholder="S.no" />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "12%" : "36%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "date"]}
                        fieldKey={[field.fieldKey, "date"]}
                      >
                        <DatePicker format={"DD-MM-YY"} />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "15%" : "45%" }}>
                      <Form.Item
                        {...field}
                        //label="Mobile"
                        name={[field.name, "grNumber"]}
                        fieldKey={[field.fieldKey, "grNumber"]}
                        rules={[{ required: true, message: "GR No required" }]}
                      >
                        <Input placeholder="GR Number" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "15%" : "45%" }}>
                      <Form.Item
                        {...field}
                        //label="Alt. Mobile"
                        name={[field.name, "content"]}
                        fieldKey={[field.fieldKey, "content"]}
                        rules={[
                          { required: true, message: "Content Required" },
                        ]}
                      >
                        <Input placeholder="Content" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "8%" : "18%" }}>
                      <Form.Item
                        {...field}
                        //  label="Email"
                        name={[field.name, "quantity"]}
                        fieldKey={[field.fieldKey, "quantity"]}
                      >
                        <InputNumber min={1} max={1000} placeholder="Pkgs" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "12%" : "36%" }}>
                      <Form.Item
                        {...field}
                        //  label="Email"
                        name={[field.name, "weight"]}
                        fieldKey={[field.fieldKey, "weight"]}
                      >
                        <Input
                          // addonBefore={
                          //   <MailOutlined className="site-form-item-icon" />
                          // }
                          placeholder="Weight"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "10%" : "25%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "paidAmount"]}
                        fieldKey={[field.fieldKey, "PaidAmount"]}
                      >
                        <Input
                          // addonBefore={
                          //   <MailOutlined className="site-form-item-icon" />
                          // }
                          placeholder="Paid Amount"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "10%" : "25%" }}>
                      <Form.Item
                        {...field}
                        //  label="Email"
                        name={[field.name, "toPayAmount"]}
                        fieldKey={[field.fieldKey, "toPayAmount"]}
                      >
                        <Input placeholder="To Pay Amount" />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: !isMobile ? "10%" : "40%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "partyId"]}
                        fieldKey={[field.fieldKey, "partyId"]}
                        rules={[
                          { required: true, message: "PrivateMark required" },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option!.children as unknown as string).includes(
                              input
                            )
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                              // .toLowerCase()
                              .localeCompare(
                                optionB!.children as unknown as string
                                //.toLowerCase()
                              )
                          }
                          placeholder="PVT Mark"
                          dropdownRender={(menu) => (
                            <>
                              <CreatePartyModal />
                              {menu}
                            </>
                          )}
                          // onChange={getInstrumentsParameters}
                        >
                          {party &&
                            party?.Party?.map((item: any, i: number) => {
                              return (
                                <Select.Option value={item?.id} key={i}>
                                  {item?.privateMark}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                    {isMobile && <Divider />}
                  </Row>
                )
              )
            )}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add GR
              </Button>
            </Form.Item>
            <div>
              <Descriptions
                //title="Responsive Descriptions"
                bordered
                column={!isMobile ? 4 : 1}
              >
                <Descriptions.Item label="Total Package">
                  {pkg}
                </Descriptions.Item>
                <Descriptions.Item label="Total Weight">
                  {weight}
                </Descriptions.Item>
                <Descriptions.Item label="Total Paid Amount">
                  ₹ {paidAmt}
                </Descriptions.Item>
                <Descriptions.Item label="Total To Pay Amount">
                  ₹ {toPayAmt}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </>
        )}
      </Form.List>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
          //onClick={resetForm}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateChallanForm;
