import moment from "moment";
import * as React from "react";
// import { request } from 'src/utils/services';
import { useMutation, useQuery } from "react-query";
// import GatepassManageModal from "../../Modal/ManageGatePass";
import {
  getGatepass,
  getGatepassGST,
  updateGatepassStatusById,
} from "../../Requests";
//import { getInstrumentById } from 'src/modules/Instruments/Requests';
import LocalStorageService from "src/utils/local-storage";
import {
  Button,
  Tooltip,
  Space,
  Input,
  Table,
  Tag,
  Popconfirm,
  message,
} from "antd";
import GatepassViewModal from "../../Modal/ShowGatePassDetails";
import {
  DownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  ColumnType,
  FilterConfirmProps,
  ColumnsType,
} from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { request } from "src/utils/services";
import { queryClient } from "src";
import { now, values } from "lodash";
import GatepassManageModal from "../../Modal/ManageGatePass";
import { isMobile } from "react-device-detect";

interface DataType {
  key: React.Key;
  id: number;
  challanNo: any;
  privateMark: any;
  grNumber: any;
  name: any;
}
type DataIndex = keyof DataType;

const GatepassList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [id, setId] = React.useState(54);
  const [formLoading, setFormLoading] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<Input>(null);
  // console.log(CookiesService.getToken(), "token")
  const DeleteGatepassMutation = useMutation(
    (id: number) =>
      request(
        "user.gatepass.delete",
        { id: id },
        {
          isDeleted: true,
          deletedAt: moment().format("YYYY-MM-DD hh:mm:ss"),
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("gatepass");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["gatepass", LocalStorageService.getAccount().id, page, size],
    () =>
      LocalStorageService.getRoles() === "ROLE_MODERATOR"
        ? getGatepassGST()
        : getGatepass(),
    {
      refetchOnWindowFocus: true,
      // enabled: false,
      keepPreviousData: true,
      staleTime: 5000,
      // refetchOnMount: false,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const confirm = async (id: any) => {
    setFormLoading(true);
    updateGatepassStatusById(id);
    setFormLoading(false);
    queryClient.invalidateQueries("gatepass");
    message.success("Payment Received");
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  // interface DataType {
  //   //key: React.Key;
  //   id: number;
  // }
  const column: ColumnsType<DataType> = isMobile
    ? [
        LocalStorageService.getRoles() !== "ROLE_MODERATOR"
          ? {
              title: "Gatepass Id",
              dataIndex: "id",
              key: "id",
              width: "100px",

              render: (text: any) => <p>{"GRGP-" + text}</p>,
              defaultSortOrder: "descend",
              sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
              fixed: "left",
            }
          : {},
        {
          title: "Challan Number",
          dataIndex: "challanNo",
          key: "challanNo",
          ...getColumnSearchProps("challanNo"),
          width: "100px",
        },
        {
          title: "Date",
          dataIndex: "gatePassDate",
          key: "gatePassDate",
          width: "100px",

          render: (text: any) => moment(text).format("D MMM y"),
        },

        {
          title: "GR Number",
          dataIndex: "grNumber",
          key: "grNumber",
          width: "100px",

          ...getColumnSearchProps("grNumber"),
          ellipsis: {
            showTitle: false,
          },
          render: (address) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
          ),
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
          width: "80px",
        },
        {
          title: "Weight",
          dataIndex: "weight",
          key: "weight",
          width: "80px",
        },

        {
          title: "Private Mark",
          dataIndex: "privateMark",
          key: "privateMark",
          width: "100px",

          ...getColumnSearchProps("privateMark"),
          ellipsis: {
            showTitle: false,
          },
          render: (address) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
          ),
        },
        {
          title: "Total Amount",
          dataIndex: "totalAmount",
          key: "totalAmount",
          width: "100px",

          render: (text: any) => <h3>{"₹" + text}</h3>,
        },

        {
          title: "Payment Status",
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          width: "120px",
          ellipsis: {
            showTitle: false,
          },
          render: (_: any, record: any) =>
            record.paymentStatus === "paid" ? (
              <Tag color="#1cb96f">
                Paid on {moment(record.paymentDate).format("D MMM y")}
              </Tag>
            ) : (
              <Popconfirm
                title="Confirm Payment?"
                onConfirm={() => confirm(record.id)}
                //onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Tag color="#B91c1f">Dues</Tag>
              </Popconfirm>
            ),
        },

        {
          title: "Action",
          dataIndex: "id",
          key: "id",
          width: "15vw",
          fixed: "right",
          render: (id: any) =>
            LocalStorageService.getRoles() === "ROLE_ADMIN" ? (
              <Space size={10}>
                <GatepassViewModal id={id} />
                <GatepassManageModal id={id} />

                <Popconfirm
                  title="Delete Gatepass?"
                  onConfirm={() => {
                    DeleteGatepassMutation.mutateAsync(id)
                      .then(({ payload }) => {
                        console.log(payload);

                        message.success(payload.message);
                      })
                      .catch((e: any) => {
                        message.error(e.message);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            ) : (
              <Space size={10}>
                <GatepassViewModal id={id} />
              </Space>
            ),
        },
      ]
    : [
        LocalStorageService.getRoles() !== "ROLE_MODERATOR"
          ? {
              title: "Gatepass Id",
              dataIndex: "id",
              key: "id",
              render: (text: any) => <p>{"GRGP-" + text}</p>,
              defaultSortOrder: "descend",
              sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
              // fixed: "left",
            }
          : {},
        {
          title: "Challan Number",
          dataIndex: "challanNo",
          key: "challanNo",
          ...getColumnSearchProps("challanNo"),
        },
        {
          title: "Date",
          dataIndex: "gatePassDate",
          key: "gatePassDate",
          render: (text: any) => moment(text).format("D MMM y"),
        },

        {
          title: "GR Number",
          dataIndex: "grNumber",
          key: "grNumber",
          ...getColumnSearchProps("grNumber"),
        },

        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "Weight",
          dataIndex: "weight",
          key: "weight",
        },

        {
          title: "Private Mark",
          dataIndex: "privateMark",
          key: "privateMark",
          ...getColumnSearchProps("privateMark"),
        },
        {
          title: "Assigned To",
          dataIndex: "name",
          key: "name",
          ...getColumnSearchProps("name"),
        },
        {
          title: "Total Amount",
          dataIndex: "totalAmount",
          key: "totalAmount",

          render: (text: any) => <h3>{"₹" + text}</h3>,
        },
        {
          title: "Payment Status",
          dataIndex: "paymentStatus",
          key: "paymentStatus",

          render: (_: any, record: any) =>
            record.paymentStatus === "paid" ? (
              <Tag color="#1cb96f">
                Paid on {moment(record.paymentDate).format("D MMM y")}
              </Tag>
            ) : (
              <Popconfirm
                title="Confirm Payment?"
                onConfirm={() => confirm(record.id)}
                //onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Tag color="#B91c1f">Dues</Tag>
              </Popconfirm>
            ),
        },

        {
          title: "Action",
          dataIndex: "id",
          key: "id",

          render: (id: any) =>
            LocalStorageService.getRoles() === "ROLE_ADMIN" ? (
              <Space size={10}>
                <GatepassViewModal id={id} />
                <GatepassManageModal id={id} />

                <Popconfirm
                  title="Delete Gatepass?"
                  onConfirm={() => {
                    DeleteGatepassMutation.mutateAsync(id)
                      .then(({ payload }) => {
                        console.log(payload);

                        message.success(payload.message);
                      })
                      .catch((e: any) => {
                        message.error(e.message);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            ) : (
              <Space size={10}>
                <GatepassViewModal id={id} />
              </Space>
            ),
        },
      ];

  return isMobile ? (
    <Table
      size="small"
      columns={column}
      dataSource={response?.Gatepass[0]}
      scroll={{ x: "100vw", y: "55vh" }}
    />
  ) : (
    <Table
      size="small"
      columns={column}
      dataSource={response?.Gatepass[0]}
      // scroll={{ x: "100vw", y: "60vh" }}
    />
  );
};

export default GatepassList;
