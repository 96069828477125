import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 32px 0;
  overflow: auto;
  background: inherit;
  @media (min-width: 768px) {
    padding: 32px 0 24px;
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
`;

export const FormTop = styled.div`
  text-align: center;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  line-height: 44px;
  a {
    text-decoration: none;
  }
`;
export const FormTitle = styled.span`
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 33px;
`;
export const FormLogo = styled.span`
  width: 44px;
  height: 44px;
  margin-right: 16px;
  vertical-align: top;

  img {
    width: 100%;
  }
`;
export const FormDescription = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
`;
export const FormMain = styled.div`
  width: 328px;
  margin: 0 auto;

  &.tab-nav-list {
    margin: 0px auto;
    font-size: 16px;
  }
  &.other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;
  }

  @media screen and (max-width: 576px) {
    width: 95%;
    max-width: 328px;
  }
`;
